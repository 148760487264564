import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import { withStyles, Button, Card, CardActions, CardContent, CircularProgress, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withSnackbar } from "notistack";
import green from "@material-ui/core/colors/green";

import Information from "../../classes/Information";
import { privateRoutes } from "../../routes";
import { formatCurrency } from "../../utils/numberFormat";
import EstimateService from "../../services/EstimateService";
import Estimate from "../../classes/Estimate";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing()
  },
  rightIcon: {
    marginLeft: theme.spacing()
  },
  wrapper: {
    margin: theme.spacing(),
    position: "relative",
    display: "inline"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  noProductText: {
    marginTop: 8,
    marginBottom: 8
  }
});

@inject("newEstimateStore", "sessionStore")
@observer
class BottomConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = { isButtonLoading: false };
  }

  saveEstimate = () => {
    const { history, newEstimateStore } = this.props;

    const estimate = new Estimate({
      ...newEstimateStore,
      amount: newEstimateStore.amount,
      currency: newEstimateStore.currency,
      totalPriceWithoutTVA: newEstimateStore.totalPriceWithoutTVA,
      totalPriceWithTVA: newEstimateStore.totalPriceWithTVA,
      computedDiscount: newEstimateStore.computedDiscount,
      information: new Information(newEstimateStore),
      additionalInformation: newEstimateStore.additionalInformation.defined,
      extraAdditionalInformation: newEstimateStore.additionalInformation.extra,
      useSpecificDeliveryAddress: newEstimateStore.useSpecificDeliveryAddress,
      deliveryAddress: newEstimateStore.useSpecificDeliveryAddress ? newEstimateStore.deliveryAddress : null,
      cashFormDeposits: newEstimateStore.cashFormDeposits.filter(deposit => !!deposit.inputValue),
      divisions: newEstimateStore.divisions || []
    });

    this.setState({ isButtonLoading: true }, () => {
      const promise = estimate.id ? EstimateService.patchEstimate(estimate) : EstimateService.postEstimate(estimate);
      promise
        .then(() => {
          this.setState({ isButtonLoading: false }, () => {
            history.push(privateRoutes.admin.estimates.listing.path);
          });
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de l'enregistrement du devis", { variant: "error" });
          this.setState({ isButtonLoading: false });
        });
    });
  };

  getFinalButton = () => {
    const { classes, newEstimateStore } = this.props;
    const { isButtonLoading } = this.state;

    return (
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          disabled={
            isButtonLoading ||
            !newEstimateStore.isNewEstimateClientOK ||
            !newEstimateStore.isNewEstimateDeliveryAddressOK ||
            !newEstimateStore.isNewEstimateComplementaryOK
          }
          onClick={this.saveEstimate}
          style={{ marginLeft: 8 }}
        >
          Enregistrer
        </Button>
        {isButtonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  render() {
    const {
      classes,
      newEstimateStore,
      handleNext,
      handleBack,
      activeStep,
      steps,
      disableNext,
      disableBack
    } = this.props;
    return (
      <Card>
        <CardContent>
          {newEstimateStore.numberDetails > 1 ? (
            <Typography variant="h5">Total pour les {newEstimateStore.numberDetails} prestations</Typography>
          ) : (
            <Typography variant="h5">Total pour la prestation</Typography>
          )}
          <div style={{ display: "flex" }}>
            <div style={{ paddingLeft: 5, textAlign: "right" }}>
              <Typography component="p" variant="body1">
                {formatCurrency(newEstimateStore.totalPriceWithoutTVA, newEstimateStore.currency)}
              </Typography>
              <Typography component="p" variant="body1">
                {formatCurrency(
                  newEstimateStore.totalPriceWithTVA - newEstimateStore.totalPriceWithoutTVA,
                  newEstimateStore.currency
                )}
              </Typography>
              <Typography component="p" variant="body1">
                {formatCurrency(newEstimateStore.amount, newEstimateStore.currency)}
              </Typography>
            </div>
            <div style={{ paddingLeft: 5 }}>
              <Typography component="p" variant="body1">
                HT
              </Typography>
              <Typography component="p" variant="body1">
                TVA
              </Typography>
              <Typography component="p" variant="body1">
                TTC
              </Typography>
            </div>
          </div>
        </CardContent>
        <CardActions style={{ display: "block" }}>
          <Button disabled={disableBack} variant="outlined" color="primary" onClick={() => handleBack()}>
            <ChevronLeftIcon className={classes.leftIcon} /> Revenir
          </Button>
          {activeStep === steps.length - 1 ? (
            this.getFinalButton()
          ) : (
            <Button variant="contained" color="primary" onClick={() => handleNext()} disabled={disableNext}>
              Passer à l&apos;étape suivante <ChevronRightIcon className={classes.rightIcon} />
            </Button>
          )}
          <div style={{ display: "block", marginLeft: 0 }}>
            {activeStep === 0 && newEstimateStore.numberDetails === 1 && !newEstimateStore.isFirstDetailOk && (
              <Typography component="p" variant="body2" className={classes.noProductText}>
                Vous devez valider au moins une prestation avant de continuer
              </Typography>
            )}
          </div>
        </CardActions>
      </Card>
    );
  }
}

export default withSnackbar(withRouter(withStyles(styles)(BottomConfirmation)));
