import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import { withStyles, Button, Card, CardActions, CardContent, CircularProgress, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import green from "@material-ui/core/colors/green";
import { withSnackbar } from "notistack";

import ContraliaService from "../../services/ContraliaService";
import Information from "../../classes/Information";
import OrderForm from "../../classes/OrderForm";
import OrderFormService from "../../services/OrderFormService";
import { privateRoutes } from "../../routes";
import EmbeddedContraliaDialog from "../dialog/EmbeddedContraliaDialog";
import { formatCurrency } from "../../utils/numberFormat";
import FinancingService from "../../services/FinancingService";
import ContraliaConfirmationDialog from "../dialog/ContraliaConfirmationDialog";
import EmbeddedYouSignDialog from "../dialog/EmbeddedYouSignDialog";
import OrderFormYouSignDialog from "../dialog/OrderFormYouSignDialog";
import { contactCanSign } from "../../utils/signature";

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing()
  },
  rightIcon: {
    marginLeft: theme.spacing()
  },
  wrapper: {
    margin: theme.spacing(),
    position: "relative",
    display: "inline"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  noProductText: {
    marginTop: 8,
    marginBottom: 8
  }
});

@inject("newOrderFormStore", "sessionStore")
@observer
class BottomConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlSignature: "",
      isButtonLoading: false,
      downloadHref: "",
      download: "",
      openSign: false,
      order: {},
      signed: false,
      openContraliaConfirmation: false,
      // YOUSIGN
      openYouSignInitDialog: false,
      openYouSignDialog: false,
      signatureRequestId: undefined,
      signers: undefined
    };
  }

  handleClose = data => {
    this.setState({ openSign: false, order: data || {}, signed: data !== null });
  };

  handleCloseYouSign = data => {
    this.setState({ openYouSignDialog: false, signed: data !== null, signers: undefined });
  };

  handleSave = async () => {
    this.setState({ isButtonLoading: true }, async () => {
      const res = await this.saveOrderForm();
      if (res) {
        this.handleBack();
      }
    });
  };

  handleBack = () => {
    const { history, newOrderFormStore } = this.props;
    newOrderFormStore.resetNewOrderForm();

    history.push(privateRoutes.admin.orderForms.path);
  };

  saveOrderForm = async () => {
    const { order } = this.state;
    if (!order.id) {
      const { newOrderFormStore } = this.props;
      const orderForm = new OrderForm({
        ...newOrderFormStore,
        amount: newOrderFormStore.amount,
        totalPriceWithoutTVA: newOrderFormStore.totalPriceWithoutTVA,
        totalPriceWithTVA: newOrderFormStore.totalPriceWithTVA,
        computedDiscount: newOrderFormStore.computedDiscount,
        information: new Information(newOrderFormStore),
        additionalInformation: newOrderFormStore.additionalInformation.defined,
        extraAdditionalInformation: newOrderFormStore.additionalInformation.extra,
        useSpecificDeliveryAddress: newOrderFormStore.useSpecificDeliveryAddress,
        deliveryAddress: newOrderFormStore.useSpecificDeliveryAddress ? newOrderFormStore.deliveryAddress : null,
        cashFormDeposits: newOrderFormStore.cashFormDeposits.filter(deposit => !!deposit.inputValue),
        divisions: newOrderFormStore.divisions || []
      });
      const savedOrderForm = await OrderFormService.postOrderForm(orderForm)
        .then(orderFormResponse => {
          this.setState({
            isButtonLoading: false,
            order: orderFormResponse
          });
          return orderFormResponse;
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de l'enregistrement du bon de commande", { variant: "error" });
          this.setState({ isButtonLoading: false });
          return null;
        });
      return savedOrderForm;
    }
    return order;
  };

  signPDF = async () => {
    this.setState({ isButtonLoading: true }, async () => {
      const orderForm = await this.saveOrderForm();
      if (orderForm) {
        this.setState({ isButtonLoading: true }, async () => {
          if (orderForm.signatureProvider === "YOUSIGN" && orderForm.signatureRequestId) {
            this.setState({ isButtonLoading: false }, () => {
              this.handleSignature(orderForm);
            });
          } else {
            OrderFormService.signOrderForm(orderForm.id)
              .then(orderFormResponse => {
                if (orderFormResponse.signatureProvider === "CONTRALIA") {
                  const { urlSignature } = orderFormResponse;
                  this.setState({
                    // transactionId: res.transactionId,
                    urlSignature,
                    openSign: true,
                    openContraliaConfirmation: false,
                    isButtonLoading: false,
                    order: orderFormResponse
                  });
                }
                if (orderFormResponse.signatureProvider === "YOUSIGN") {
                  this.setState({ order: orderFormResponse, isButtonLoading: false }, () => {
                    this.handleSignature(orderForm);
                  });
                }
              })
              .catch(() => {
                const { enqueueSnackbar } = this.props;
                enqueueSnackbar("Une erreur est survenue lors de l'initialisation de la signature du bon de commande", {
                  variant: "error"
                });
                this.setState({ isButtonLoading: false });
              });
          }
        });
      }
    });
  };

  handleInitYouSign = async () => {
    await this.saveOrderForm();
    this.setState({ openYouSignInitDialog: true });
  };

  handleSignature = orderForm => {
    OrderFormService.getOrderFormSignature(orderForm.id)
      .then(res => {
        if (res.signatureProvider === "YOUSIGN") {
          this.setState({
            signatureRequestId: orderForm.signatureRequestId,
            signers: res.signers,
            openYouSignDialog: true
          });
        }
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors du chargement de la signature", { variant: "error" });
      });
  };

  handleSendSofincoContext = () => {
    const { order } = this.state;
    const { information, contact, number, amount } = order;
    const parsedInformation = JSON.parse(information);

    this.setState({ isButtonLoading: true }, async () => {
      FinancingService.sendSofincoContext({
        financingForm: parsedInformation.financingForm,
        contact,
        externalId: number,
        amount
      })
        .then(res => {
          window.open(res.url);
          this.setState({ isButtonLoading: false });
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la transmission des informations à Sofinco", {
            variant: "error"
          });
          this.setState({ isButtonLoading: false });
        });
    });
  };

  handleSendFranfinanceContext = () => {
    const { order } = this.state;

    this.setState({ isButtonLoading: true }, async () => {
      FinancingService.sendFranfinanceContext(order, "order")
        .then(res => {
          window.open(res.url);
          this.setState({ isButtonLoading: false });
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la transmission des informations à Sofinco", {
            variant: "error"
          });
          this.setState({ isButtonLoading: false });
        });
    });
  };

  downloadPDF = async () => {
    const { order } = this.state;

    this.setState({ isButtonLoading: true }, async () => {
      try {
        const document = await ContraliaService.getDownloadPDF(order.transactionId);
        const downloadHref = window.URL.createObjectURL(document);
        this.setState({ isButtonLoading: false, downloadHref, download: `${order.number}.pdf` });
      } catch (err) {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du bon de commande", {
          variant: "error"
        });
        this.setState({ isButtonLoading: false });
      }
    });
  };

  simulateClick = e => {
    // Avoid a blank page by checking if e is null or not.
    // Just remove the if condition to check the behavior.
    if (e !== null) {
      e.click();
    }
    this.setState({ downloadHref: "" });
    this.handleBack();
  };

  getFinalButton = () => {
    const { classes, newOrderFormStore, sessionStore } = this.props;
    const {
      isButtonLoading,
      downloadHref,
      download,
      signed,
      order,
      urlSignature,
      openContraliaConfirmation,
      openYouSignInitDialog
    } = this.state;

    const parsedInformations = order && order.information ? JSON.parse(order.information) : null;

    return (
      <div className={classes.wrapper}>
        {(signed && (order.transactionId || order.signatureRequestId)) || urlSignature ? (
          <React.Fragment>
            {signed && (order.transactionId || order.signatureRequestId) && (
              <Button
                variant="contained"
                color="primary"
                disabled={isButtonLoading || !newOrderFormStore.isNewOrderFormComplementaryOK}
                onClick={this.downloadPDF}
                style={{ marginRight: 8 }}
              >
                Télécharger
              </Button>
            )}
            <Button variant="contained" color="primary" disabled={isButtonLoading} onClick={this.handleBack}>
              Retour au listing
            </Button>
            {sessionStore.userHasAccess({ requiredFeatures: ["OPTION_SOFINCO"] }) &&
              signed &&
              (order.transactionId || order.signatureRequestId) &&
              order.paymentCondition === "FINANCING" &&
              parsedInformations &&
              parsedInformations.financingForm.financingType === "SOFINCO" && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isButtonLoading}
                  onClick={this.handleSendSofincoContext}
                  style={{ marginLeft: 8 }}
                >
                  Envoyer vers Sofinco
                </Button>
              )}
            {sessionStore.userHasAccess({ requiredFeatures: ["OPTION_FRANFINANCE"] }) &&
              signed &&
              (order.transactionId || order.signatureRequestId) &&
              order.paymentCondition === "FINANCING" &&
              parsedInformations &&
              parsedInformations.financingForm.financingType === "FRANFINANCE" && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isButtonLoading}
                  onClick={this.handleSendFranfinanceContext}
                  style={{ marginLeft: 8 }}
                >
                  Envoyer vers Franfinance
                </Button>
              )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              variant="contained"
              color="primary"
              disabled={isButtonLoading || !newOrderFormStore.isNewOrderFormComplementaryOK}
              onClick={this.handleSave}
            >
              {order.id ? "Retour au listing" : "Enregistrer et quitter"}
            </Button>
            {sessionStore.userHasAccess({
              requiredAcls: ["SIGN_WRITE"],
              requiredFeatures: ["SIGN"]
            }) && (
              <Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    isButtonLoading ||
                    !newOrderFormStore.isNewOrderFormComplementaryOK ||
                    !contactCanSign(newOrderFormStore.client)
                  }
                  onClick={() => {
                    if (sessionStore.user.company.signatureProvider !== "YOUSIGN") {
                      this.setState({ openContraliaConfirmation: true });
                    } else {
                      this.handleInitYouSign();
                    }
                  }}
                  style={{ marginLeft: 8 }}
                >
                  Signer le bon de commande
                </Button>
                <ContraliaConfirmationDialog
                  open={openContraliaConfirmation}
                  onCancel={() => this.setState({ openContraliaConfirmation: false })}
                  onConfirm={() => {
                    this.setState({ openContraliaConfirmation: false });
                    this.signPDF();
                  }}
                  contact={newOrderFormStore.client}
                />
                {openYouSignInitDialog && (
                  <OrderFormYouSignDialog
                    onSignatureInit={_updatedOrderForm => this.setState({ order: _updatedOrderForm })}
                    currentUser={sessionStore.user}
                    open={openYouSignInitDialog}
                    doc={order}
                    handleClose={() => this.setState({ openYouSignInitDialog: false })}
                  />
                )}
              </Fragment>
            )}
          </React.Fragment>
        )}

        {isButtonLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        {downloadHref !== "" && (
          <a ref={this.simulateClick} href={downloadHref} download={download} style={{ display: "none" }}>
            Download
          </a>
        )}
      </div>
    );
  };

  render() {
    const {
      classes,
      newOrderFormStore,
      handleNext,
      handleBack,
      activeStep,
      steps,
      disableNext,
      disableBack
    } = this.props;
    const { openSign, urlSignature, order, openYouSignDialog, signers, signatureRequestId } = this.state;

    return (
      <Fragment>
        <Card>
          <CardContent>
            {newOrderFormStore.numberDetails > 1 ? (
              <Typography variant="h5">Total pour les {newOrderFormStore.numberDetails} prestations</Typography>
            ) : (
              <Typography variant="h5">Total pour la prestation</Typography>
            )}
            <div style={{ display: "flex" }}>
              <div style={{ paddingLeft: 5, textAlign: "right" }}>
                <Typography component="p" variant="body1">
                  {formatCurrency(newOrderFormStore.totalPriceWithoutTVA, newOrderFormStore.currency)}
                </Typography>
                <Typography component="p" variant="body1">
                  {formatCurrency(
                    newOrderFormStore.totalPriceWithTVA - newOrderFormStore.totalPriceWithoutTVA,
                    newOrderFormStore.currency
                  )}
                </Typography>
                <Typography component="p" variant="body1">
                  {formatCurrency(newOrderFormStore.amount, newOrderFormStore.currency)}
                </Typography>
              </div>
              <div style={{ paddingLeft: 5 }}>
                <Typography component="p" variant="body1">
                  HT
                </Typography>
                <Typography component="p" variant="body1">
                  TVA
                </Typography>
                <Typography component="p" variant="body1">
                  TTC
                </Typography>
              </div>
            </div>
          </CardContent>
          <CardActions style={{ display: "block" }}>
            <Button disabled={disableBack || order.id} variant="outlined" color="primary" onClick={() => handleBack()}>
              <ChevronLeftIcon className={classes.leftIcon} /> Revenir
            </Button>
            {activeStep === steps.length - 1 ? (
              this.getFinalButton()
            ) : (
              <Button variant="contained" color="primary" onClick={() => handleNext()} disabled={disableNext}>
                Passer à l&apos;étape suivante <ChevronRightIcon className={classes.rightIcon} />
              </Button>
            )}
            <div style={{ display: "block", marginLeft: 0 }}>
              {activeStep === 0 && newOrderFormStore.numberDetails === 1 && !newOrderFormStore.isFirstDetailOk && (
                <Typography component="p" variant="body2" className={classes.noProductText}>
                  Vous devez valider au moins une prestation avant de continuer
                </Typography>
              )}
            </div>
          </CardActions>
        </Card>

        <EmbeddedContraliaDialog
          open={openSign}
          UrlSignature={urlSignature}
          handleClose={this.handleClose}
          transactionId={order.transactionId}
        />
        {openYouSignDialog && order.id && (
          <EmbeddedYouSignDialog
            open={openYouSignDialog}
            signers={signers}
            signatureRequestId={signatureRequestId}
            handleClose={this.handleCloseYouSign}
            documentType="ORDERFORM"
            doc={order}
          />
        )}
      </Fragment>
    );
  }
}

export default withSnackbar(withRouter(withStyles(styles)(BottomConfirmation)));
