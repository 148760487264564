import DoneIcon from "@material-ui/icons/Done";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import TimerOffIcon from "@material-ui/icons/TimerOff";
import DescriptionIcon from "@material-ui/icons/Description";
import ClearIcon from "@material-ui/icons/Clear";

import OrderFormService from "../services/OrderFormService";

export const ORDERFROM_STATUS = {
  DRAFT: {
    dashboardLabel: "Brouillon",
    label: "Brouillon",
    labelPlural: "Brouillons",
    color: "#777"
  },
  DRAFT_PENDING: {
    dashboardLabel: "En attente",
    label: "En attente",
    tooltip: orderForm =>
      `Vous pouvez signer ce bon jusqu'au ${OrderFormService.signatureDeadline(orderForm).format(
        "DD/MM/YYYY [à] HH:mm"
      )}`,
    labelPlural: "En attente",
    color: "#777",
    hideForFilter: true
  },
  DRAFT_EXPIRED: {
    label: "Délai dépassé",
    tooltip: "Vous avez dépassé le délai alloué à la signature",
    color: "#777",
    hideForFilter: true
  },
  SIGNED: {
    dashboardLabel: "Signés",
    label: "Signé",
    labelPlural: "Signés",
    color: "#337ab7"
  },
  INVOICED: {
    dashboardLabel: "Facturés",
    label: "Facturé",
    labelPlural: "Facturés",
    color: "#5cb85c"
  },
  CANCEL: {
    dashboardLabel: "Annulés",
    label: "Annulé",
    labelPlural: "Annulés",
    color: "#d9534f"
  }
};

export const INVOICE_STATUS = {
  DRAFT: {
    dashboardLabel: "Non validées",
    label: "Non validée",
    labelPlural: "Non validées",
    color: "#777"
  },
  VALIDATED: {
    dashboardLabel: "Validées",
    label: "Validée",
    labelPlural: "Validées",
    color: "#337ab7"
  },
  SENT: {
    dashboardLabel: "Envoyées",
    label: "Envoyée",
    labelPlural: "Envoyées",
    color: "#f0ad4e"
  },
  PAID: {
    dashboardLabel: "Payées",
    label: "Payée",
    labelPlural: "Payées",
    color: "#759C71"
  },
  PARTIALLY_PAID: {
    dashboardLabel: "Partiellement payées",
    label: "Partiellement payée",
    labelPlural: "Partiellement payées",
    color: "#BDC899"
  },
  CANCEL: {
    dashboardLabel: "Annulées",
    label: "Annulée par avoir",
    labelPlural: "Annulées par avoir",
    color: "#17A2B8"
  },
  CREDIT_NOTE: {
    dashboardLabel: "Avoirs",
    label: "Avoir",
    labelPlural: "Avoirs",
    color: "#5bc0de"
  }
};

export const ADDITIONAL_DOCUMENT_STATUS = {
  DRAFT: {
    label: "Brouillon",
    color: "#777"
  },
  VALIDATED: {
    label: "Validé",
    color: "#759C71"
  },
  SENT: {
    label: "Envoyé",
    color: "#f0ad4e"
  },
  SENT_EXPIRED: {
    label: "Délai dépassé",
    color: "#d9534f"
  },
  SIGNED: {
    label: "Signé",
    color: "#337ab7"
  },
  REFUSED: {
    label: "Refusé",
    color: "#d9534f"
  },
  CANCEL: {
    label: "Annulé",
    color: "#d9534f"
  }
};

export const SIGNATURE_STATUS = {
  DRAFT: {
    dashboardLabel: "En attente",
    label: "En attente",
    labelPlural: "En attente",
    color: "#777",
    Icon: DescriptionIcon,
    canBeFiltered: true
  },
  CANCEL: {
    dashboardLabel: "Annulée",
    label: "Annulée",
    labelPlural: "Annulées",
    color: "#777",
    Icon: ClearIcon,
    canBeFiltered: true
  },
  WAITING_SIGN: {
    dashboardLabel: "En cours",
    label: "En cours",
    labelPlural: "En cours",
    color: "#337ab7",
    Icon: QueryBuilderIcon,
    canBeFiltered: true
  },
  EXPIRED: {
    dashboardLabel: "Délai dépassé",
    label: "Délai dépassé",
    labelPlural: "Délai dépassé",
    color: "#d9534f",
    Icon: TimerOffIcon,
    canBeFiltered: false
  },
  SIGNED: {
    dashboardLabel: "Signées",
    label: "Signée",
    labelPlural: "Signée",
    color: "#759C71",
    Icon: DoneIcon,
    canBeFiltered: true
  }
};

export const ESTIMATE_STATUS = {
  DRAFT: {
    dashboardLabel: "En attente",
    label: "En attente",
    labelPlural: "En attente",
    color: "#777"
  },
  VALIDATED: {
    dashboardLabel: "Validés",
    label: "Validé",
    labelPlural: "Validés",
    color: "#337ab7"
  },
  SENT: {
    dashboardLabel: "Envoyés",
    label: "Envoyé",
    labelPlural: "Envoyés",
    color: "#f0ad4e"
  },
  SENT_EXPIRED: {
    label: "Délai dépassé",
    tooltip: "Vous avez dépassé le délai alloué à la signature",
    color: "#f0ad4e",
    hideForFilter: true
  },
  SIGNED: {
    dashboardLabel: "Signés",
    label: "Signé",
    labelPlural: "Signés",
    color: "#5bc0de"
  },
  CANCEL: {
    dashboardLabel: "Annulés / Refusés",
    label: "Annulé",
    labelPlural: "Annulés / Refusés",
    color: "#d9534f"
  },
  REFUSED: {
    label: "Refusé",
    labelPlural: "Refusés",
    color: "#d9534f"
  },
  INVOICED: {
    dashboardLabel: "Facturés",
    label: "Facturé",
    labelPlural: "Facturés",
    color: "#5cb85c"
  }
};

export const PAYMENT_TYPE = {
  OTHER: "Autre",
  CARD: "Carte",
  CHECK: "Chèque",
  CURRENCY: "Espèces",
  FINANCING: "Financement",
  DIRECT_DEBIT: "Prélèvement automatique",
  TRANSFER: "Virement"
};
