import React, { Component } from "react";
import { Box, Button, Grid, MenuItem, TextField, withStyles, CircularProgress, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Formik } from "formik";
import Decimal from "decimal.js-light";
import { green } from "@material-ui/core/colors";
import { withSnackbar } from "notistack";

import moment from "moment";
import FinancingService, {
  getTextFromInsuranceCount,
  INSURANCE_COUNT,
  SofincoSchema
} from "../../../../services/FinancingService";
import FinancingItem from "./FinancingItem";
import { formatCurrency, formatNumber } from "../../../../utils/numberFormat";

const styles = theme => ({
  totalCard: {
    backgroundColor: "#F7F7F7",
    borderRadius: 4,
    padding: `${theme.spacing(2)}px !important`
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});
@inject("newEstimateStore", "sessionStore")
@observer
class SofincoForm extends Component {
  constructor(props) {
    super(props);
    this.state = { simulationStatus: "DRAFT" };
  }

  componentDidMount() {
    const { newEstimateStore } = this.props;
    const { financingForm } = newEstimateStore;
    if (financingForm && financingForm._sofinco && financingForm._sofinco.simulationResult) {
      this.setState({ simulationStatus: "DONE" });
    }
  }

  render() {
    const { amount, sessionStore, classes, newEstimateStore } = this.props;
    const { simulationStatus } = this.state;

    const { company } = sessionStore.user;
    const { financingForm } = newEstimateStore;

    return (
      <Formik
        initialValues={{
          scaleCode: financingForm && financingForm._sofinco ? financingForm._sofinco.scaleCode : "",
          dueNumbers: financingForm ? `${financingForm.instalmentNumber}` : "0",
          insurances: financingForm ? financingForm.insuranceCount : "0"
        }}
        validationSchema={SofincoSchema}
        onSubmit={values => {
          this.setState({ simulationStatus: "PROCESSING" });
          newEstimateStore.resetFinancingForm();
          FinancingService.getSofincoSimulation({
            ...values,
            amount: amount || 10000,
            brandStructure: company.brandStructure,
            equipmentCode: company.equipmentCode
          })
            .then(res => {
              if (res) {
                const simulation = res[0];
                newEstimateStore.patchFinancingForm({
                  _sofinco: {
                    scaleCode: values.scaleCode,
                    simulationDate: new Date(),
                    simulationResult: simulation,
                    brandStructure: company.brandStructure,
                    equipmentCode: company.equipmentCode
                  },
                  financingInstitution: "SOFINCO",
                  instalmentNumber: simulation.dueNumber,
                  loanDuration: simulation.loanDuration,
                  instalmentAmount: simulation.monthlyAmountWithoutInsurance,
                  totalAmount: simulation.totalAmountWithoutInsurance,
                  monthlyCostInsurance: simulation.monthlyInsuranceAmount,
                  insuranceCount: values.insurances,
                  instalmentAmountWithInsurance: simulation.monthlyAmountWithInsurance,
                  totalAmountWithInsurance: simulation.totalAmountWithInsurance,
                  overdrawnRate: simulation.annualPercentageRate,
                  taeg: simulation.annualPercentageRateOfCharge,
                  annualPercentageRateOfInsurance: simulation.annualPercentageRateOfInsurance,
                  annualMarkUprate: new Decimal(
                    (((simulation.totalCostWithoutInsurance / simulation.amount) * 100) / simulation.dueNumber) * 12
                  )
                    .toDecimalPlaces(3, Decimal.ROUND_HALF_EVEN)
                    .toNumber()
                    .toFixed(3)
                });
              }
              if (!res) {
                const { enqueueSnackbar } = this.props;
                enqueueSnackbar("Les données fournies ne permettent pas d'obtenir une simulation", {
                  variant: "warning"
                });
              }
              this.setState({ simulationStatus: !!res ? "DONE" : "NO_MATCH" });
            })
            .catch(() => {
              this.setState({ simulationStatus: "ERROR" });
              const { enqueueSnackbar } = this.props;
              enqueueSnackbar("Une erreur est survenue lors de la simulation", { variant: "error" });
            });
        }}
      >
        {formikProps => (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="scaleCode"
                label="Barême"
                variant="outlined"
                type="text"
                fullWidth
                required
                value={formikProps.values.scaleCode}
                onChange={formikProps.handleChange}
                helperText={formikProps.errors.scaleCode || " "}
                error={!!formikProps.errors.scaleCode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="dueNumbers"
                label="Nombre de mensualités"
                variant="outlined"
                type="number"
                fullWidth
                required
                inputProps={{ min: "0", step: "1" }}
                value={formikProps.values.dueNumbers}
                onChange={formikProps.handleChange}
                helperText={formikProps.errors.dueNumbers || " "}
                error={!!formikProps.errors.dueNumbers}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                label="Nombre d'assuré(s)"
                required
                value={formikProps.values.insurances}
                onChange={formikProps.handleChange}
                helperText=" "
                name="insurances"
                fullWidth
                variant="outlined"
              >
                {INSURANCE_COUNT.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {getTextFromInsuranceCount(option.value)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 12 }}>
              <Button
                onClick={formikProps.handleSubmit}
                variant="contained"
                color="primary"
                disabled={simulationStatus === "PROCESSING"}
              >
                Simuler
                {simulationStatus === "PROCESSING" && <CircularProgress size={24} className={classes.buttonProgress} />}
              </Button>
              {simulationStatus !== "DRAFT" && (
                <Box style={{ marginTop: 18 }}>
                  {simulationStatus === "DONE" && (
                    <Box className={classes.totalCard}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem title="Durée du contrat" value={`${financingForm.loanDuration} mois`} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem title="Nombre de mensualités" value={financingForm.instalmentNumber} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem
                            title="Montant des mensualités hors assurance"
                            value={formatCurrency(financingForm.instalmentAmount)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem
                            title="Montant total dû hors assurance"
                            value={formatCurrency(financingForm.totalAmount)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem
                            title="Coût mensuel de l'assurance choisie"
                            value={formatCurrency(financingForm.monthlyCostInsurance)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem
                            title="Nombre d'assuré(s)"
                            value={getTextFromInsuranceCount(financingForm.insuranceCount)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem
                            title="Montant des mensualités avec assurance choisie"
                            value={formatCurrency(financingForm.instalmentAmountWithInsurance)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem
                            title="Montant total dû avec assurance choisie"
                            value={formatCurrency(financingForm.totalAmountWithInsurance)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem
                            title="Taux Débiteur Fixe"
                            value={`${formatNumber(financingForm.overdrawnRate, 3)} %`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem
                            title="Taux Annuel Effectif Global (TAEG)"
                            value={`${formatNumber(financingForm.taeg, 3)} %`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem
                            title="Taux Annuel Effectif de l'Assurance (TAEA)"
                            value={`${formatNumber(financingForm.annualPercentageRateOfInsurance, 3)} %`}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FinancingItem
                            title="Taux de Majoration Annuel (TMA)"
                            value={`${formatNumber(financingForm.annualMarkUprate, 3)} %`}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                          <Typography variant="caption">
                            Simulation du {moment(financingForm._sofinco.simulationDate).format("DD/MM/yyyy [à] HH:mm")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Formik>
    );
  }
}

export default withSnackbar(withStyles(styles)(SofincoForm));
