import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import FranfinanceLogo from "../../estimate/complementaryStepForms/franfinance.png";
import SofincoLogo from "../../estimate/complementaryStepForms/sofinco.svg";
import AdminService from "../../../services/AdminService";
import SofincoEquipmentCodeSelect from "../../financing/SofincoEquipmentCodeSelect";
import SofincoCampaignIdSelect from "../../financing/SofincoCampaignIdSelect";
import ArkeaLogo from "../../estimate/complementaryStepForms/arkea_final.png";

const useStyles = makeStyles(theme => ({
  link: {
    display: "block"
  },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  }
}));

export default function CompanyFinancing({ company, onPatch }) {
  const classes = useStyles();
  const [franfinanceToken, setFranfinanceToken] = React.useState(company.franfinanceToken);
  const [brandStructure, setBrandStructure] = React.useState(company.brandStructure);
  const [equipmentCode, setEquipmentCode] = React.useState(company.equipmentCode);
  const [sofincoPartnerId, setSofincoPartnerId] = React.useState(company.sofincoPartnerId || "");
  const [sofincoCampaignId, setSofincoCampaignId] = React.useState(company.sofincoCampaignId || "");
  const [sofincoBusinessProviderId, setSofincoBusinessProviderId] = React.useState(
    company.sofincoBusinessProviderId || ""
  );
  const [arkeaDevapps, setArkeaDevapps] = React.useState(company.arkeaDevapps || "");
  const [arkeaProductName, setArkeaProductName] = React.useState(company.arkeaProductName || "");
  const [arkeaSimulationDisclaimerSignature, setArkeaSimulationDisclaimerSignature] = React.useState(
    company.arkeaSimulationDisclaimerSignature || ""
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleReset = () => {
    setFranfinanceToken(company.franfinanceToken);
    setBrandStructure(company.brandStructure);
    setEquipmentCode(company.equipmentCode);
    setSofincoPartnerId(company.sofincoPartnerId);
    setSofincoCampaignId(company.sofincoCampaignId);
    setSofincoBusinessProviderId(company.sofincoBusinessProviderId);
    setArkeaDevapps(company.arkeaDevapps);
    setArkeaProductName(company.arkeaProductName);
    setArkeaSimulationDisclaimerSignature(company.arkeaSimulationDisclaimerSignature);
  };

  const handleSubmit = () => {
    AdminService.patchCompany({
      ...company,
      franfinanceToken,
      brandStructure,
      equipmentCode,
      sofincoPartnerId,
      sofincoCampaignId,
      sofincoBusinessProviderId,
      arkeaDevapps,
      arkeaProductName,
      arkeaSimulationDisclaimerSignature
    })
      .then(updatedCompany => {
        onPatch(updatedCompany);
        setFranfinanceToken(updatedCompany.franfinanceToken);
        setBrandStructure(updatedCompany.brandStructure);
        setEquipmentCode(updatedCompany.equipmentCode);
        setSofincoPartnerId(updatedCompany.sofincoPartnerId);
        setSofincoCampaignId(updatedCompany.sofincoCampaignId);
        setSofincoBusinessProviderId(updatedCompany.sofincoBusinessProviderId);
        enqueueSnackbar("L'entreprise a été mise à jour", { variant: "success" });
      })
      .catch(err => {
        console.log(" ERROR ", err);
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'entreprise", {
          variant: "error"
        });
      });
  };

  if (company.features.includes("OPTION_SOFINCO") || company.features.includes("OPTION_FRANFINANCE")) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="h3">
                Financement
              </Typography>
              {company.features.includes("OPTION_SOFINCO") && (
                <Box
                  style={{
                    marginBottom:
                      company.features.includes("OPTION_FRANFINANCE") || company.features.includes("OPTION_ARKEA")
                        ? 24
                        : 0
                  }}
                >
                  <img width={100} src={SofincoLogo} alt="Logo SOFINCO" />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Numéro d&apos;agrément
                  </Typography>
                  <TextField
                    size="small"
                    name="sofincoBusinessProviderId"
                    placeholder="XX"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={sofincoBusinessProviderId}
                    onChange={e => setSofincoBusinessProviderId(e.target.value)}
                  />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Code enseigne
                  </Typography>
                  <TextField
                    size="small"
                    name="brandStructure"
                    placeholder="XX"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={brandStructure}
                    onChange={e => setBrandStructure(e.target.value)}
                  />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Nature du bien
                  </Typography>
                  <SofincoEquipmentCodeSelect
                    hideLabel
                    value={equipmentCode}
                    onChange={e => setEquipmentCode(e.target.value)}
                    selectProps={{ size: "small" }}
                    formControlProps={{ size: "small" }}
                  />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Identifiant du partenaire
                  </Typography>
                  <TextField
                    size="small"
                    name="sofincoPartnerId"
                    placeholder="XX"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={sofincoPartnerId}
                    onChange={e => setSofincoPartnerId(e.target.value)}
                  />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Identifiant de la campagne
                  </Typography>
                  <SofincoCampaignIdSelect
                    value={sofincoCampaignId}
                    onChange={e => setSofincoCampaignId(e.target.value)}
                    hideLabel
                    selectProps={{ size: "small" }}
                    formControlProps={{ size: "small" }}
                  />
                </Box>
              )}
              {company.features.includes("OPTION_FRANFINANCE") && (
                <Box style={{ marginBottom: company.features.includes("OPTION_ARKEA") ? 24 : 0 }}>
                  <img width={100} src={FranfinanceLogo} alt="Logo FRANFINANCE" />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Chaine d&apos;authentification
                  </Typography>
                  <TextField
                    size="small"
                    name="franfinanceToken"
                    placeholder="xxxxxxxxx"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={franfinanceToken}
                    onChange={e => setFranfinanceToken(e.target.value)}
                  />
                </Box>
              )}
              {company.features.includes("OPTION_ARKEA") && (
                <Box>
                  <img width={100} src={ArkeaLogo} alt="Logo Arkea" />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Devapps
                  </Typography>
                  <TextField
                    size="small"
                    name="arkeaDevapps"
                    placeholder="xxxxxxxxx"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={arkeaDevapps}
                    onChange={e => setArkeaDevapps(e.target.value)}
                  />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Nom du produit
                  </Typography>
                  <TextField
                    size="small"
                    name="arkeaProductName"
                    placeholder="xxxxxxxxx"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={arkeaProductName}
                    onChange={e => setArkeaProductName(e.target.value)}
                    helperText="Exemple de financement pour l'achat XXX au prix de ..."
                  />
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Informations sur l&apos;entreprise pour le pavé financier
                  </Typography>
                  <TextField
                    size="small"
                    name="arkeaSimulationDisclaimerSignature"
                    placeholder="xxxxxxxxx"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={arkeaSimulationDisclaimerSignature}
                    onChange={e => setArkeaSimulationDisclaimerSignature(e.target.value)}
                  />
                </Box>
              )}
            </CardContent>
            <CardActions style={{ justifyContent: "flex-end" }}>
              <Button variant="text" onClick={handleReset}>
                Annuler
              </Button>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Enregistrer
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  }
  return <div />;
}
