import { useFormikContext } from "formik";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Button, CircularProgress, MenuItem, TextField } from "@material-ui/core";
import { useDebounce } from "use-debounce";
import { Alert } from "@material-ui/lab";
import FinancingService from "../../../../../services/FinancingService";

function ArkeaInsuranceInput({ amount, deposit }) {
  const { values, errors, touched, setFieldValue, handleBlur } = useFormikContext();
  const [debouncedNumberOfPayments] = useDebounce(values.numberOfPayments, 500);

  const { data: fetchedAssurances, error, isFetching, refetch } = useQuery({
    queryKey: [
      "arkea_assurances",
      {
        scaleId: values.scale && values.scale.id ? values.scale.id : null,
        numberOfPayments: debouncedNumberOfPayments,
        amount,
        deposit
      }
    ],
    queryFn: async () => {
      return FinancingService.getArkeaInsurances({
        scaleId: values.scale && values.scale.id ? values.scale.id : null,
        numberOfPayments: debouncedNumberOfPayments,
        purchaseAmount: amount,
        depositAmount: deposit
      })
        .then(res => {
          if (values.borrowerInsurance.id) {
            const insurance = res.find(_insurance => _insurance.id === values.borrowerInsurance.id);
            if (insurance) {
              setFieldValue("borrowerInsurance.insuranceWording", insurance.insuranceWording);
            }
          }
          return res;
        })
        .catch(err => {
          throw err;
        });
    },
    enabled:
      !!values.scale &&
      !!values.scale.id &&
      !!debouncedNumberOfPayments &&
      !errors.numberOfPayments &&
      debouncedNumberOfPayments > 1,
    retry: false
  });

  const handleChangeInsurance = event => {
    if (event.target.value === "NONE") {
      setFieldValue("borrowerInsurance.id", "NONE");
      setFieldValue("borrowerInsurance.insuranceWording", "Aucune assurance");
    } else {
      const insurance = fetchedAssurances.find(_insurance => _insurance.id === event.target.value);
      setFieldValue("borrowerInsurance.id", insurance.id);
      setFieldValue("borrowerInsurance.insuranceWording", insurance.insuranceWording);
    }
  };

  if (
    !!values.scale &&
    !!values.scale.id &&
    !!debouncedNumberOfPayments &&
    !errors.numberOfPayments &&
    fetchedAssurances
  ) {
    return (
      <TextField
        select
        label="Assurance"
        required
        value={values.borrowerInsurance.id}
        onChange={handleChangeInsurance}
        onBlur={handleBlur}
        helperText={
          touched.borrowerInsurance &&
          touched.borrowerInsurance.id &&
          errors.borrowerInsurance &&
          errors.borrowerInsurance.id
            ? errors.borrowerInsurance.id
            : ""
        }
        error={
          touched.borrowerInsurance &&
          touched.borrowerInsurance.id &&
          !!errors.borrowerInsurance &&
          !!errors.borrowerInsurance.id
        }
        name="borrowerInsurance.id"
        fullWidth
        variant="outlined"
      >
        {fetchedAssurances.map(borrowerInsurance => (
          <MenuItem key={borrowerInsurance.id} value={borrowerInsurance.id}>
            {borrowerInsurance.insuranceWording}
          </MenuItem>
        ))}
        <MenuItem value="NONE">Aucune assurance</MenuItem>
      </TextField>
    );
  }
  if (isFetching && !errors.numberOfPayments) {
    return <CircularProgress />;
  }
  if (error && !errors.numberOfPayments) {
    return (
      <Alert
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={() => refetch()}>
            Réessayer
          </Button>
        }
      >
        Une erreur est survenue lors de la récupération des assurances
      </Alert>
    );
  }
  return null;
}

export default ArkeaInsuranceInput;
