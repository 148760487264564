import React, { Fragment, useState } from "react";
import { IconButton, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

function TableAction({ children, disabled, size = "medium", onOpen, onClose }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    if (onOpen) {
      event.stopPropagation();
      onOpen();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    if (onClose) {
      onClose();
    }
    setAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <Fragment>
      <IconButton
        size={size}
        aria-label="Actions"
        aria-owns={anchorEl ? "simple-menu" : null}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} onClick={handleClose}>
        {children}
      </Menu>
    </Fragment>
  );
}

export default TableAction;
