import React, { Component, Fragment } from "react";
import { Divider, List } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import WarningIcon from "@material-ui/icons/Warning";
import red from "@material-ui/core/colors/red";

import { superAdmin, privateRoutes } from "../../routes";
import SimpleMenuItem from "./SimpleMenuItem";
import CollapsableMenuItem from "./CollapsableMenuItem";
import renderIcon from "../../utils/menuIcon";

function WarningSuffix() {
  return <WarningIcon style={{ color: red[600], marginLeft: 10 }} />;
}

@inject("sessionStore")
@observer
class AppMenu extends Component {
  render() {
    const { handleToggle, sessionStore } = this.props;
    const {
      isSuperAdmin,
      company: { CGV, withdrawal }
    } = sessionStore.user;
    const items = [];

    /*
      COMPANY MENU
    */
    const companyMenu = [];
    if (sessionStore.userHasAccess({ requiredAcls: ["DASHBOARD_READ"], requiredFeatures: ["DASHBOARD"] }))
      companyMenu.push({ label: "Tableau de bord", path: privateRoutes.admin.dashboard.path });
    if (sessionStore.isCompanyAdmin() && sessionStore.userHasAccess({ requiredFeatures: ["DASHBOARD", "SIGN"] }))
      companyMenu.push({ label: "Info conso", path: privateRoutes.admin.conso.path });
    companyMenu.push({ label: "Clients", path: privateRoutes.common.clients.path });
    if (
      sessionStore.userHasAccess({
        requiredAcls: ["DOC_SIGNATURE_READ", "DOC_SIGNATURE_MY"],
        aclType: "oneof",
        requiredFeatures: ["DOC_SIGNATURE"]
      })
    )
      companyMenu.push({ label: "Signatures PDF", path: privateRoutes.admin.signatures.listing.path });
    if (
      sessionStore.userHasAccess({
        requiredAcls: ["ESTIMATE_MY", "ESTIMATE_READ"],
        aclType: "oneof",
        requiredFeatures: ["ESTIMATE"]
      })
    )
      companyMenu.push({ label: "Devis", path: privateRoutes.admin.estimates.listing.path });
    if (
      sessionStore.userHasAccess({
        requiredAcls: ["ORDER_MY", "ORDER_READ"],
        aclType: "oneof",
        requiredFeatures: ["ORDER"]
      })
    )
      companyMenu.push({ label: "Bons de commande", path: privateRoutes.admin.orderForms.path });
    if (
      sessionStore.userHasAccess({
        requiredAcls: ["INVOICE_MY", "INVOICE_READ"],
        aclType: "oneof",
        requiredFeatures: ["INVOICE"]
      })
    )
      companyMenu.push({ label: "Factures", path: privateRoutes.admin.invoices.listing.path });
    if (
      sessionStore.userHasAccess({
        requiredAcls: ["ADDITIONAL_DOCUMENT_MY", "ADDITIONAL_DOCUMENT_READ"],
        aclType: "oneof",
        requiredFeatures: ["ADDITIONAL_DOCUMENT"]
      })
    )
      companyMenu.push({
        label: "Documents additionnels",
        path: privateRoutes.common.additionalDocuments.worksAcceptanceListing.path
      });
    if (
      sessionStore.userHasAccess({
        requiredAcls: ["INVOICE_MY", "INVOICE_READ"],
        aclType: "oneof",
        requiredFeatures: ["INVOICE"]
      })
    )
      companyMenu.push({ label: "Paiements", path: privateRoutes.admin.payments.listing.path });

    if (companyMenu.length > 0)
      items.push({
        label: "Mon Entreprise",
        subItems: companyMenu
      });

    /*
      ADMINISTRATION MENU
    */
    const adminMenu = [];
    if (
      sessionStore.userHasAccess({
        requiredAcls: ["PRODUCT_WRITE"],
        requiredFeatures: ["PRODUCT"]
      })
    )
      adminMenu.push({ label: "Catégories", path: privateRoutes.admin.productCategories.path });
    adminMenu.push({ label: "Catalogue", path: privateRoutes.admin.products.path });

    if (
      sessionStore.userHasAccess({
        requiredAcls: ["USER_READ"],
        requiredFeatures: ["USER"]
      })
    )
      adminMenu.push({ label: "Utilisateurs", path: privateRoutes.admin.sellers.path });
    if (adminMenu.length > 0)
      items.push({
        label: "Administration",
        subItems: adminMenu
      });

    /*
      SETTINGS MENU
    */
    const settingsMenu = sessionStore.userHasAccess({
      requiredAcls: ["SETTING_READ", "SETTING_WRITE"],
      aclType: "oneof",
      requiredFeatures: ["SETTING"] // Native feature
    })
      ? [
          { label: "Général", path: privateRoutes.admin.settings.general.path },
          {
            label: "CGV",
            path: privateRoutes.admin.settings.terms.path,
            suffix: CGV === null ? <WarningSuffix /> : null
          },
          {
            label: "Rétractation",
            path: privateRoutes.admin.settings.withdrawal.path,
            suffix: withdrawal === null ? <WarningSuffix /> : null
          },
          { label: "Logos", path: privateRoutes.admin.settings.logos.path },
          {
            label: "Paiement",
            path: privateRoutes.admin.settings.payment.path
          }
        ]
      : [];
    if (
      sessionStore.userHasAccess({
        requiredAcls: ["SETTING_READ", "SETTING_WRITE"],
        aclType: "oneof",
        requiredFeatures: ["SETTING"]
      }) &&
      (sessionStore.userHasAccess({ requiredFeatures: ["OPTION_SOFINCO"] }) ||
        sessionStore.userHasAccess({ requiredFeatures: ["OPTION_FRANFINANCE"] }))
    ) {
      settingsMenu.push({ label: "Financement", path: privateRoutes.admin.settings.financing.path });
    }
    if (
      sessionStore.userHasAccess({
        requiredAcls: ["SETTING_READ", "SETTING_WRITE"],
        aclType: "oneof",
        requiredFeatures: ["SETTING"] // Native feature
      })
    ) {
      settingsMenu.push({ label: "Profils utilisateurs", path: privateRoutes.admin.profiles.path });
      settingsMenu.push({ label: "Couleurs", path: privateRoutes.admin.settings.colors.path });
    }
    if (settingsMenu.length > 0)
      items.push({
        label: "Paramétrage",
        subItems: settingsMenu
      });

    return (
      <Fragment>
        {isSuperAdmin &&
          Object.keys(superAdmin)
            .filter(key => superAdmin[key].hideInMenu !== true)
            .map(key => (
              <SimpleMenuItem
                notExact
                key={superAdmin[key].path}
                handleToggle={handleToggle}
                item={{ path: superAdmin[key].path, label: superAdmin[key].text, icon: renderIcon("lock") }}
              />
            ))}
        {isSuperAdmin && <Divider />}

        <List>
          {items &&
            items.length > 0 &&
            items.map(item => {
              if (item.path === undefined) {
                return <CollapsableMenuItem key={item.label} item={item} handleToggle={handleToggle} />;
              }
              return <SimpleMenuItem key={item.path} item={item} handleToggle={handleToggle} />;
            })}
        </List>
      </Fragment>
    );
  }
}

export default withRouter(AppMenu);
