export const FEATURES = [
  {
    key: "DASHBOARD",
    label: "Tableau de bord",
    description: "Permet de visualiser les données en un coup d'oeil",
    isRootFeature: false
  },
  {
    key: "CONTACT",
    label: "Annuaire",
    description: "Permet de créer et gérer des clients",
    isRootFeature: true
  },
  {
    key: "PRODUCT",
    label: "Catalogue",
    description: "Permet de créer et gérer les produits/services",
    isRootFeature: true
  },
  {
    key: "ORDER",
    label: "Bons de commande",
    description: "Permet la création de bons de commande",
    isRootFeature: false
  },
  {
    key: "ESTIMATE",
    label: "Devis",
    description: "Permet la création de devis",
    isRootFeature: false
  },
  {
    key: "INVOICE",
    label: "Factures",
    description: "Permet la création de factures",
    isRootFeature: false
  },
  {
    key: "DOC_SIGNATURE",
    label: "Signatures PDF",
    description: "Envoyer des PDF en signature",
    isRootFeature: false
  },
  {
    key: "ADDITIONAL_DOCUMENT",
    label: "Documents additionnels",
    description: "Ajouter des documents liés aux devis, bons de commande et factures",
    isRootFeature: false
  },
  {
    key: "SIGN",
    label: "Signatures KingKang",
    description: "Envoyer des documents créés depuis KingKang en signature",
    isRootFeature: true
  },
  {
    key: "USER",
    label: "Utilisateurs",
    description: "Permet la gestion de plusieurs utilisateurs/profils",
    isRootFeature: false
  },
  {
    key: "SETTING",
    label: "Paramètres",
    description: "Permet la consultation/modification des paramètres",
    isRootFeature: true
  }
];

export const OPTIONS = [
  {
    key: "OPTION_OPTIMUS",
    label: "Optimus Prime",
    description: "Permet l'ajout de prime(s) dans un devis ou un bon de commande",
    isRootFeature: false
  },
  {
    key: "OPTION_ADDITIONAL_INFORMATION",
    label: "Mentions complémentaires",
    description: "Permet l'ajout de textes après les modalités de paiement",
    isRootFeature: false
  },
  {
    key: "OPTION_CURRENCY",
    label: "Deviser le monde",
    description: "Permet la modification de la devise dans un devis, un bon de commande ou une facture",
    isRootFeature: false
  },
  {
    key: "OPTION_SOFINCO",
    label: "Sofinco",
    description: "Permet d'utiliser le moteur de simulation Sofinco pour les financements",
    isRootFeature: false
  },
  {
    key: "OPTION_FRANFINANCE",
    label: "Franfinance",
    description: "Permet d'utiliser le moteur de simulation Franfinance pour les financements",
    isRootFeature: false
  },
  {
    key: "OPTION_COFIDIS_PROJEXIO",
    label: "Cofidis - Projexio",
    description: "Permet d'utiliser Cofidis - Projexio pour les financements (aucune liaison)",
    isRootFeature: false
  },
  {
    key: "OPTION_ARKEA",
    label: "Arkea",
    description: "Permet d'utiliser le moteur de simulation Arkea pour les financements",
    isRootFeature: false
  },
  {
    key: "OPTION_CONTACT_FINANCIAL_ID",
    label: "Identifiant client",
    description: "Identifie le client sur les documents émis par Kingkang",
    isRootFeature: false
  },
  {
    key: "OPTION_CONTACT_DELIVERY_ADDRESS",
    label: "Adresse de livraison client",
    description: "Permet de renseigner une adresse de livraison différente de l'adresse de facturation",
    isRootFeature: false
  },
  {
    key: "OPTION_SECONDARY_ADDRESS",
    label: "Adresse secondaire",
    description: "Permet de renseigner deux adresses pour l'entreprise",
    isRootFeature: false
  },
  {
    key: "OPTION_ADDITIONAL_DOCUMENT_VAT_DECLARATION",
    label: "Document additionnel : Attestation simplifiée TVA taux réduit",
    description: "Permet de générer une attestation simplifiée de TVA à partir d'un document (Cerfa 1301-SD)",
    isRootFeature: false
  },
  {
    key: "OPTION_ADDITIONAL_DOCUMENT_WORKS_ACCEPTANCE",
    label: "Document additionnel : PV de fin de travaux",
    description: "Permet de créer des PV de fin de travaux à partir d'un document",
    isRootFeature: false
  },
  {
    key: "OPTION_CEGID_QUADRA_INVOICES_EXPORT",
    label: "Export des factures vers CEGID Quadra",
    description: "Permet d'exporter les factures vers CEGID Quadra",
    isRootFeature: false
  },
  {
    key: "OPTION_LOCO_ZIPCODE",
    label: "Code postal sans validation",
    description: "Permet de modifier saisir n'importe quel code postal (alphanumérique avec espace et/ou tiret)",
    isRootFeature: false
  },
  {
    key: "OPTION_DOCUMENT_DIVISION",
    label: "Vente partagée",
    description: "Permet de déclarer plusieurs utilisateurs pour un même document",
    isRootFeature: false
  }
];

export const ACLS = [
  { key: "DASHBOARD_READ", relatedFeature: "DASHBOARD", label: "Visualiser les tableaux de bord" },
  { key: "CONTACT_READ", relatedFeature: "CONTACT", label: "Visualiser tous les clients de l'entreprise" },
  { key: "CONTACT_WRITE", relatedFeature: "CONTACT", label: "Modifier tous les clients de l'entreprise" },
  { key: "CONTACT_EXPORT", relatedFeature: "CONTACT", label: "Exporter les clients" },
  { key: "PRODUCT_WRITE", relatedFeature: "PRODUCT", label: "Créer / modifier un produit/service du catalogue" },
  { key: "ORDER_MY", relatedFeature: "ORDER", label: "Créer, visualiser et intéragir avec ses bons de commandes" },
  { key: "ORDER_READ", relatedFeature: "ORDER", label: "Visualiser tous les bons de commande de l'entreprise" },
  { key: "ORDER_WRITE", relatedFeature: "ORDER", label: "Modifier tous les bons de commande de l'entreprise" },
  { key: "ORDER_CANCEL", relatedFeature: "ORDER", label: "Annuler un bon de commande" },
  { key: "ORDER_EXPORT", relatedFeature: "ORDER", label: "Exporter les bons de commande" },
  { key: "ESTIMATE_MY", relatedFeature: "ESTIMATE", label: "Créer, visualiser et intéragir avec ses devis" },
  { key: "ESTIMATE_READ", relatedFeature: "ESTIMATE", label: "Visualiser tous les devis de l'entreprise" },
  { key: "ESTIMATE_WRITE", relatedFeature: "ESTIMATE", label: "Modifier tous les devis de l'entreprise" },
  { key: "ESTIMATE_CANCEL", relatedFeature: "ESTIMATE", label: "Annuler un devis" },
  { key: "ESTIMATE_EXPORT", relatedFeature: "ESTIMATE", label: "Exporter les devis" },
  { key: "INVOICE_MY", relatedFeature: "INVOICE", label: "Créer, visualiser et intéragir avec ses factures" },
  { key: "INVOICE_READ", relatedFeature: "INVOICE", label: "Visualiser toutes les factures de l'entreprise" },
  { key: "INVOICE_WRITE", relatedFeature: "INVOICE", label: "Modifier toutes les factures de l'entreprise" },
  { key: "INVOICE_EXPORT", relatedFeature: "INVOICE", label: "Exporter les factures" },
  {
    key: "DOC_SIGNATURE_MY",
    relatedFeature: "DOC_SIGNATURE",
    label: "Créer, visualiser et intéragir avec ses documents envoyés en signature"
  },
  {
    key: "DOC_SIGNATURE_READ",
    relatedFeature: "DOC_SIGNATURE",
    label: "Visualiser tous les documents envoyés en signature de l'entreprise"
  },
  {
    key: "DOC_SIGNATURE_WRITE",
    relatedFeature: "DOC_SIGNATURE",
    label: "Modifier tous les documents envoyés en signature de l'entreprise"
  },
  {
    key: "ADDITIONAL_DOCUMENT_MY",
    relatedFeature: "ADDITIONAL_DOCUMENT",
    label: "Créer, visualiser et intéragir avec ses documents additionnels"
  },
  {
    key: "ADDITIONAL_DOCUMENT_READ",
    relatedFeature: "ADDITIONAL_DOCUMENT",
    label: "Visualiser tous les documents additionnels de l'entreprise"
  },
  {
    key: "ADDITIONAL_DOCUMENT_WRITE",
    relatedFeature: "ADDITIONAL_DOCUMENT",
    label: "Modifier tous les documents additionnels de l'entreprise"
  },
  { key: "SIGN_WRITE", relatedFeature: "SIGN", label: "Faire signer un document" },
  { key: "USER_READ", relatedFeature: "USER", label: "Voir tous les utilisateurs de mon entreprise" },
  { key: "USER_WRITE", relatedFeature: "USER", label: "Ajouter, modifier des utilisateurs et droits" },
  { key: "SETTING_READ", relatedFeature: "SETTING", label: "Voir les paramètres" },
  { key: "SETTING_WRITE", relatedFeature: "SETTING", label: "Modifier les paramètres" }
];
