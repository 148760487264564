import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useQuery } from "@tanstack/react-query";
import { FieldArray, Form, Formik } from "formik";
import React, { Fragment } from "react";
import * as Yup from "yup";

import Decimal from "decimal.js-light";
import CompanyService from "../../services/CompanyService";
import SubmitForm from "./SubmitForm";
import TotalRateAlert from "./TotalRateAlert";

const DocumentDivisionsSchema = Yup.object().shape({
  documentDivisions: Yup.array().of(
    Yup.object().shape({
      user: Yup.object()
        .shape({
          id: Yup.string().required("Ce champ est requis")
        })
        .required("Ce champ est requis"),
      rate: Yup.number().required("Ce champ est requis")
    })
  )
});

export default function DocumentDivisionUpdateDialog({ documentDivisions, onUpdate, currentUser }) {
  const [open, setOpen] = React.useState(false);

  const { data: users, error, isLoading } = useQuery({
    queryKey: ["company_users"],
    queryFn: () => CompanyService.getSimplifiedUsers(),
    refetchOnWindowFocus: false,
    cacheTime: 50000,
    staleTime: 50000,
    retry: (failureCount, _error) => _error.status !== 401 && _error.status !== 400 && failureCount < 3
  });

  return (
    <Fragment>
      <Button onClick={() => setOpen(true)} variant="outlined" color="primary">
        Modifier
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        {users ? (
          <Formik
            initialValues={{ documentDivisions }}
            onSubmit={values => {
              const groupedValues = values.documentDivisions.reduce((acc, value) => {
                const _user = acc.find(documentDivision => documentDivision.user.id === value.user.id);
                if (!_user) {
                  acc.push({ ...value, rate: new Decimal(value.rate) });
                } else {
                  const index = acc.indexOf(_user);
                  acc[index].rate = acc[index].rate.plus(value.rate);
                }
                return acc;
              }, []);
              const sorted = groupedValues
                .filter(value => value.rate > 0)
                .map(value => ({ ...value, rate: value.rate.toNumber() }));
              onUpdate(sorted);
              setOpen(false);
            }}
            validationSchema={DocumentDivisionsSchema}
          >
            {({ values, setFieldValue, errors }) => {
              return (
                <Form>
                  <DialogTitle>Mise à jour de la répartition de la vente</DialogTitle>
                  <DialogContent>
                    <FieldArray
                      name="documentDivisions"
                      render={arrayHelpers => (
                        <Grid container spacing={2}>
                          {values.documentDivisions.map((documentDivision, index) => (
                            <Grid item xs={12} key={`division-${index + 1}`}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    name={`documentDivisions.${index}.user.id`}
                                    type="text"
                                    placeholder="Utilisateur"
                                    label="Utilisateur"
                                    margin="none"
                                    fullWidth
                                    value={documentDivision.user.id}
                                    onChange={e => {
                                      setFieldValue(`documentDivisions.${index}.user.id`, e.target.value);
                                      const _user = users.find(user => user.id === e.target.value);
                                      setFieldValue(`documentDivisions.${index}.user.firstname`, _user.firstname);
                                      setFieldValue(`documentDivisions.${index}.user.lastname`, _user.lastname);
                                    }}
                                    disabled={documentDivision.user && documentDivision.user.isCurrentUser}
                                    error={
                                      errors.documentDivisions &&
                                      errors.documentDivisions[index] &&
                                      !!errors.documentDivisions[index].user
                                    }
                                    helperText={
                                      errors.documentDivisions &&
                                      errors.documentDivisions[index] &&
                                      !!errors.documentDivisions[index].user
                                        ? "Ce champ est requis"
                                        : ""
                                    }
                                  >
                                    {users.map(user => (
                                      <MenuItem key={user.id} value={user.id}>
                                        {user.firstname} {user.lastname}
                                        {user.id === currentUser.id ? " (Vous)" : ""}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange={e => {
                                      setFieldValue(
                                        `documentDivisions.${index}.rate`,
                                        e.target.value !== "" ? parseFloat(e.target.value) : ""
                                      );
                                    }}
                                    name={`documentDivisions.${index}.rate`}
                                    type="number"
                                    placeholder="Valeur"
                                    label="Valeur"
                                    value={documentDivision.rate}
                                    inputProps={{
                                      min: 0,
                                      step: 1,
                                      max: 100
                                    }}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                  {!documentDivision.user || !documentDivision.user.isCurrentUser ? (
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      style={{ height: "100%" }}
                                    >
                                      <Button
                                        size="small"
                                        variant="text"
                                        onClick={() => arrayHelpers.remove(index)}
                                        color="primary"
                                      >
                                        <DeleteIcon color="primary" />
                                      </Button>
                                    </Box>
                                  ) : (
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      style={{ height: "100%" }}
                                    >
                                      <Button size="small" variant="text" onClick={() => {}} disabled>
                                        <DeleteIcon color="inherit" />
                                      </Button>
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                          <Grid item xs={12}>
                            <Box display="flex" justifyContent="center" alignItems="center" style={{ paddingTop: 14 }}>
                              <Button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    user: {
                                      id: "",
                                      firstname: "",
                                      lastname: ""
                                    },
                                    rate: 0
                                  })
                                }
                                variant="outlined"
                                color="primary"
                              >
                                Ajouter un utilisateur
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    />
                    <div style={{ marginTop: 12, marginBottom: 6 }}>
                      <TotalRateAlert />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)}>Annuler</Button>
                    <SubmitForm />
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Fragment>
            <DialogTitle>Mise à jour de la répartition de la vente</DialogTitle>
            <DialogContent>
              {isLoading && <CircularProgress />}
              {error && (
                <Typography variant="body1">
                  Une erreur est survenue lors de la récupération des utilisateurs
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Annuler</Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
    </Fragment>
  );
}
