import React, { useCallback, useMemo } from "react";
import {
  Card,
  CardActions,
  Button,
  CardContent,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { isValidPhoneNumber } from "libphonenumber-js";

import { Alert } from "@material-ui/lab";
import ValidatorService from "../../services/ValidatorService";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";
import { canYousignClassic, canYousignEmailOTP, canYousignPhoneOTP } from "../../services/YouSignService";

function SignatureSignatory({
  contact,
  onClick,
  onDelete,
  index,
  updateContact,
  sessionStore,
  emailAlreadyUsed,
  phoneAlreadyUsed
}) {
  const providerIsYouSign = sessionStore.user.company.signatureProvider === "YOUSIGN";

  const canOtp = useMemo(() => {
    if (!contact.phone) return false;
    if (providerIsYouSign) {
      return isValidPhoneNumber(contact.phone, "FR");
    }
    return ValidatorService.validateMobilePhone(contact.phone);
  }, [contact]);

  const handleUpdate = useCallback(() => {
    onClick(contact, index);
  }, [onClick, contact, index]);

  const handleDelete = useCallback(() => {
    onDelete(index);
  }, [onDelete, index]);

  const handleChange = e => {
    updateContact({ ...contact, signatureType: e.target.value });
  };

  return (
    <ConfirmationDialog situationText="Êtes-vous sûr de vouloir supprimer ce signataire ?">
      {confirmBeforeAct => (
        <Card>
          <CardContent>
            <Typography variant="h5">
              Signataire #{index + 1} - {contact.firstname} {contact.lastname}
            </Typography>
            {emailAlreadyUsed ? (
              <Alert severity="warning" style={{ marginTop: 16 }}>
                L&apos;adresse email de ce signataire est utilisée par un autre signataire.
              </Alert>
            ) : null}
            {phoneAlreadyUsed ? (
              <Alert severity="warning" style={{ marginTop: 16 }}>
                Le numéro de téléphone de ce signataire est utilisé par un autre signataire.
              </Alert>
            ) : null}
            {providerIsYouSign ? (
              <FormControl style={{ marginTop: 16 }} component="fieldset">
                <FormLabel component="legend">Niveau de signature</FormLabel>
                <RadioGroup
                  aria-label="clientSignatureType"
                  name={`clientSignatureType-${index}`}
                  value={contact.signatureType}
                  onChange={handleChange}
                >
                  {canYousignClassic(sessionStore) && (
                    <FormControlLabel value="no_otp" control={<Radio color="primary" />} label="Manuscrite" />
                  )}
                  {canYousignEmailOTP(sessionStore) && (
                    <FormControlLabel
                      value="otp_email"
                      control={<Radio color="primary" />}
                      label="Avec validation par code email"
                    />
                  )}
                  {canYousignPhoneOTP(sessionStore) && (
                    <FormControlLabel
                      disabled={!contact.phone}
                      value="otp_sms"
                      control={<Radio color="primary" />}
                      label={`Avec validation par code SMS${contact.phone ? ` - ${contact.phone}` : ""}`}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            ) : (
              <Typography variant="subtitle1" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                {canOtp ? `Signature SMS possible : ${contact.phone}` : "Non éligible à la signature SMS"}
              </Typography>
            )}
          </CardContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ color: "#F40B27" }}
              onClick={e =>
                stopPropagationForConfirmation(
                  e,
                  confirmBeforeAct(() => handleDelete())
                )
              }
            >
              Supprimer
            </Button>
            <Button variant="outlined" color="primary" onClick={handleUpdate}>
              Modifier
            </Button>
          </CardActions>
        </Card>
      )}
    </ConfirmationDialog>
  );
}

export default inject("sessionStore")(observer(props => <SignatureSignatory {...props} />));
