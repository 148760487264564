import { Button } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";

export default function ArkeaSubmitForSimulation({ disabled }) {
  const { values, handleSubmit } = useFormikContext();

  if (
    !values.scale ||
    !values.scale.id ||
    !values.numberOfPayments ||
    !values.borrowerInsurance ||
    !values.borrowerInsurance.id
  ) {
    return null;
  }
  return (
    <div>
      <Button onClick={handleSubmit} variant="contained" color="primary" disabled={disabled}>
        Simuler
      </Button>
    </div>
  );
}
