import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import DocumentDivisionRow from "./DocumentDivisionRow";
import DocumentDivisionUpdateDialog from "./DocumentDivisionUpdateDialog";

export default function DocumentDivisionList({ documentDivisions, onUpdate, currentUser }) {
  const sortedArray = useMemo(() => {
    const _sortedArray = [...documentDivisions];
    return _sortedArray.sort((a, b) => b.rate - a.rate);
  }, [documentDivisions]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Répartition</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" gap={2}>
          {sortedArray.map((documentDivision, index) => (
            <DocumentDivisionRow
              key={`documentDivision-${index + 1}`}
              documentDivision={documentDivision}
              currentUser={currentUser}
            />
          ))}
          <Box display="flex" justifyContent="center" style={{ marginTop: 10 }}>
            <DocumentDivisionUpdateDialog
              documentDivisions={documentDivisions}
              onUpdate={onUpdate}
              currentUser={currentUser}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
