import { Alert } from "@material-ui/lab";
import Decimal from "decimal.js-light";
import React from "react";

export default function ArkeaDataDeltaAlert({ financingForm, amount, totalDeposit, dueNumber, insuranceId, scaleId }) {
  const hasInput =
    financingForm._arkea &&
    financingForm._arkea.scaleId &&
    financingForm._arkea.numberOfPayments &&
    financingForm._arkea.borrowerInsuranceId &&
    financingForm._arkea.paymentPostponement !== undefined;
  if (!hasInput) return null;

  if (
    new Decimal(financingForm._arkea.purchaseAmount)
      .toDecimalPlaces(3, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(3) !==
      new Decimal(amount)
        .toDecimalPlaces(3, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(3) ||
    new Decimal(financingForm._arkea.depositAmount)
      .toDecimalPlaces(3, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(3) !==
      new Decimal(totalDeposit)
        .toDecimalPlaces(3, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(3) ||
    financingForm._arkea.scaleId !== scaleId ||
    financingForm._arkea.numberOfPayments !== dueNumber ||
    financingForm._arkea.borrowerInsuranceId !== insuranceId
  ) {
    return (
      <Alert severity="info" style={{ marginTop: 6 }}>
        Les données diffèrent de celles utilisées pour la simulation
      </Alert>
    );
  }

  return null;
}
