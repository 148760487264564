import React, { Component } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import SofincoForm from "./FinancingForms/SofincoForm";
import CustomForm from "./FinancingForms/CustomForm";
import CommonForm from "./FinancingForms/CommonForm";
import SofincoLogo from "./sofinco.svg";
import FranfinanceLogo from "./franfinance.png";
import ArkeaLogo from "./arkea_final.png";
import FranfinanceForm from "./FinancingForms/FranfinanceForm";
import CofidisProjexioLogo from "./projexio.png";
import CofidisProjexioForm from "./FinancingForms/CofidisProjexioForm";
import ArkeaForm from "./FinancingForms/ArkeaForm";

const styles = () => ({ label: { display: "flex" } });

@inject("newOrderFormStore", "sessionStore")
@observer
class FinancingForm extends Component {
  handleChange = (e, newType) => {
    const { newOrderFormStore } = this.props;
    newOrderFormStore.handleChangeFinancingType(newType);
  };

  render() {
    const { newOrderFormStore, sessionStore, classes } = this.props;
    const { financingType } = newOrderFormStore;
    const hasSofinco =
      sessionStore.userHasAccess({ requiredFeatures: ["OPTION_SOFINCO"] }) && sessionStore.user.canSofinco;
    const hasFranfinance =
      sessionStore.userHasAccess({ requiredFeatures: ["OPTION_FRANFINANCE"] }) && sessionStore.user.canFranfinance;
    const hasCofidisProjexio =
      sessionStore.userHasAccess({
        requiredFeatures: ["OPTION_COFIDIS_PROJEXIO"]
      }) && sessionStore.user.canCofidisProjexio;
    const hasArkea = sessionStore.userHasAccess({ requiredFeatures: ["OPTION_ARKEA"] }) && sessionStore.user.canArkea;

    const total =
      Number(newOrderFormStore.amount) -
      Number(newOrderFormStore.computedFinancingFormDeposit) -
      Number(newOrderFormStore.financingForm.endWorkPayment);

    return (
      <Grid container spacing={hasSofinco ? 2 : 0}>
        <Grid item xs={12}>
          <CommonForm />
        </Grid>
        {(hasSofinco || hasFranfinance || hasCofidisProjexio || hasArkea) && (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Etablissement financier</FormLabel>
              <RadioGroup name="financingType" value={financingType} onChange={this.handleChange} row>
                {hasSofinco && (
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="SOFINCO"
                    control={<Radio color="primary" />}
                    label={<img src={SofincoLogo} alt="Logo SOFINCO" />}
                  />
                )}
                {hasFranfinance && (
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="FRANFINANCE"
                    control={<Radio color="primary" />}
                    label={<img style={{ marginTop: 6 }} width={100} src={FranfinanceLogo} alt="Logo FRANFINANCE" />}
                  />
                )}
                {hasCofidisProjexio && (
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="COFIDIS_PROJEXIO"
                    control={<Radio color="primary" />}
                    label={<img width={100} src={CofidisProjexioLogo} alt="Logo COFIDIS PROJEXIO" />}
                  />
                )}
                {hasArkea && (
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="ARKEA"
                    control={<Radio color="primary" />}
                    label={<img src={ArkeaLogo} width={110} alt="Logo ARKEA" />}
                  />
                )}
                <FormControlLabel value="OTHER" control={<Radio color="primary" />} label="Autre" />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          {financingType === "OTHER" && <CustomForm />}
          {financingType === "SOFINCO" && <SofincoForm amount={total} />}
          {financingType === "FRANFINANCE" && (
            <FranfinanceForm
              amount={Number(newOrderFormStore.amount)}
              totalDeposit={
                Number(newOrderFormStore.computedFinancingFormDeposit) +
                Number(newOrderFormStore.financingForm.endWorkPayment)
              }
            />
          )}
          {financingType === "COFIDIS_PROJEXIO" && <CofidisProjexioForm />}
          {financingType === "ARKEA" && <ArkeaForm />}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(FinancingForm);
