import React from "react";
import { Dialog, DialogContent, DialogTitle, Button } from "@material-ui/core";
import { Formik } from "formik";

import { inject, observer } from "mobx-react";
import ClientForm from "../form/ClientForm";
import { ClientSchema } from "../../services/ClientService";

const defaultDeliveryAddress = {
  business: "",
  lastname: "",
  firstname: "",
  phone: "",
  email: "",
  address: "",
  zipCode: "",
  city: ""
};

function DeliveryAddressDialog({ deliveryAddress, handleClose, sessionStore }) {
  const onClose = _client => {
    handleClose(_client);
  };

  const zipCodeRegex =
    sessionStore.user &&
    sessionStore.user.company &&
    sessionStore.user.company.features &&
    sessionStore.user.company.features.includes("OPTION_LOCO_ZIPCODE")
      ? /^[0-9A-z\s-]+$/
      : /^[0-9]{5}$/;

  return (
    <Dialog
      modal="false"
      open
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
    >
      <DialogTitle id="alert-dialog-title">Saisie de l&apos;adresse de livraison</DialogTitle>

      <DialogContent style={{ width: "70vw", maxWidth: 900, paddingBottom: 16 }}>
        <div style={{ paddingTop: 20 }}>
          <Formik
            enableReinitialize
            initialValues={deliveryAddress ? { ...deliveryAddress } : { ...defaultDeliveryAddress }}
            validationSchema={ClientSchema({ zipCodeRegex })}
            onSubmit={values => {
              onClose({ ...values });
            }}
          >
            {formikProps => (
              <div>
                <ClientForm woDOM formik={formikProps} excludeDates />
                <div style={{ display: "flex", justifyContent: "center", marginTop: 25 }}>
                  <Button onClick={() => onClose()} color="primary" style={{ marginRight: 15 }}>
                    Annuler
                  </Button>
                  <Button onClick={() => formikProps.handleSubmit()} color="primary" variant="contained">
                    Enregistrer
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default inject("sessionStore")(observer(props => <DeliveryAddressDialog {...props} />));
