import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
// import IconButton from "@material-ui/core/IconButton";
// import { Decimal } from "decimal.js-light";
import { MenuItem, withStyles } from "@material-ui/core";

// import { FINANCIAL_METHOD } from "../../../../stores/NewOrderFormStore";
import { getTextFromInsuranceCount, INSURANCE_COUNT } from "../../../../services/FinancingService";

const styles = theme => ({
  textfieldNoMarginTop: {
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.spacing()}px`
    }
  }
});

@inject("newOrderFormStore")
@observer
class CustomForm extends Component {
  render() {
    const { classes, newOrderFormStore } = this.props;
    const { financingForm, handleChangeFinancingForm } = newOrderFormStore;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.item}>
          <TextField
            name="financingInstitution"
            label="Etablissement financier"
            margin="normal"
            variant="outlined"
            type="text"
            fullWidth
            value={financingForm.financingInstitution}
            onChange={handleChangeFinancingForm}
          />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <TextField
            name="financingApplicationFee"
            label="Frais de dossier"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.financingApplicationFee}
            onChange={handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="loanDuration"
            label="Durée du contrat"
            margin="normal"
            variant="outlined"
            type="text"
            fullWidth
            value={financingForm.loanDuration}
            onChange={handleChangeFinancingForm}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="instalmentNumber"
            label="Nombre de mensualités"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.instalmentNumber}
            onChange={handleChangeFinancingForm}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="instalmentAmount"
            label="Montant des mensualités hors assurance"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.instalmentAmount}
            onChange={handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="totalAmount"
            label="Montant total dû hors assurance"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.totalAmount}
            onChange={handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="monthlyCostInsurance"
            label="Coût mensuel de l'assurance"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.monthlyCostInsurance}
            onChange={handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            select
            label="Nombre d'assuré(s)"
            value={financingForm.insuranceCount}
            onChange={handleChangeFinancingForm}
            name="insuranceCount"
            fullWidth
            variant="outlined"
            margin="normal"
          >
            {INSURANCE_COUNT.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {getTextFromInsuranceCount(option.value)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="instalmentAmountWithInsurance"
            label="Montant des mensualités avec assurance choisie"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.instalmentAmountWithInsurance}
            onChange={handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="totalAmountWithInsurance"
            label="Montant total dû avec assurance choisie"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.totalAmountWithInsurance}
            onChange={handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="overdrawnRate"
            label="Taux Débiteur Fixe"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.overdrawnRate}
            onChange={handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="taeg"
            label="Taux Annuel Effectif Global (TAEG)"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.taeg}
            onChange={handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="annualPercentageRateOfInsurance"
            label="Taux Annuel Effectif de l'Assurance (TAEA)"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.annualPercentageRateOfInsurance}
            onChange={handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item}>
          <TextField
            name="annualMarkUprate"
            label="Taux de Majoration Annuel (TMA)"
            margin="normal"
            variant="outlined"
            type="number"
            fullWidth
            value={financingForm.annualMarkUprate}
            onChange={handleChangeFinancingForm}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(inject("newOrderFormStore")(observer(props => <CustomForm {...props} />)));
