import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Button, CircularProgress, MenuItem, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import Decimal from "decimal.js-light";
import { Alert } from "@material-ui/lab";
import FinancingService from "../../../../../services/FinancingService";

function ArkeaScaleInput({ amount, deposit }) {
  const { values, handleBlur, errors, touched, setFieldValue } = useFormikContext();

  const { data: fetchedScales, error, refetch } = useQuery({
    queryKey: ["arkea_scales"],
    queryFn: async () => {
      return FinancingService.getArkeaScales()
        .then(res => {
          if (values.scale.id) {
            const scale = res.find(_scale => _scale.id === values.scale.id);
            if (scale) {
              setFieldValue("scale.id", scale.id);
              setFieldValue("scale.scaleWording", scale.scaleWording);
              setFieldValue("scale.numberOfPaymentsMin", scale.numberOfPaymentsMin);
              setFieldValue("scale.numberOfPaymentsMax", scale.numberOfPaymentsMax);
              setFieldValue("scale.monthlyPaymentMin", scale.monthlyPaymentMin);
              setFieldValue("scale.monthlyPaymentMax", scale.monthlyPaymentMax);
              setFieldValue("scale.creditAmountMin", scale.creditAmountMin);
              setFieldValue("scale.creditAmountMax", scale.creditAmountMax);
              setFieldValue("scale.creditDepositPercentMin", scale.creditDepositPercentMin);
              setFieldValue("scale.paymentPostponements", scale.paymentPostponements[0]);
            }
          }
          return res;
        })
        .catch(err => {
          throw err;
        });
    },
    refetchOnWindowFocus: false,
    cacheTime: 50000,
    staleTime: 50000,
    retry: (failureCount, _error) => _error.status !== 401 && _error.status !== 400 && failureCount < 3
  });

  const handleChangeScale = e => {
    const scale = fetchedScales.find(_scale => _scale.id === e.target.value);
    setFieldValue("scale.id", scale.id);
    setFieldValue("scale.scaleWording", scale.scaleWording);
    setFieldValue("scale.numberOfPaymentsMin", scale.numberOfPaymentsMin);
    setFieldValue("scale.numberOfPaymentsMax", scale.numberOfPaymentsMax);
    setFieldValue("scale.monthlyPaymentMin", scale.monthlyPaymentMin);
    setFieldValue("scale.monthlyPaymentMax", scale.monthlyPaymentMax);
    setFieldValue("scale.creditAmountMin", scale.creditAmountMin);
    setFieldValue("scale.creditAmountMax", scale.creditAmountMax);
    setFieldValue("scale.creditDepositPercentMin", scale.creditDepositPercentMin);
    setFieldValue("scale.paymentPostponements", scale.paymentPostponements[0]);
    setFieldValue("numberOfPayments", 1);
    setFieldValue("borrowerInsurance.id", "");
    setFieldValue("borrowerInsurance.insuranceWording", "");
  };

  const financingAmount = new Decimal(amount).minus(deposit).toNumber();

  return fetchedScales ? (
    <TextField
      select
      label="Barème"
      required
      value={values.scale.id}
      onChange={handleChangeScale}
      onBlur={handleBlur}
      helperText={touched.scale && touched.scale.id && errors.scale && errors.scale.id ? errors.scale.id : ""}
      error={touched.scale && touched.scale.id && !!errors.scale && !!errors.scale.id}
      name="scale.id"
      fullWidth
      variant="outlined"
    >
      {fetchedScales.map(scale => (
        <MenuItem
          key={scale.id}
          value={scale.id}
          disabled={financingAmount < scale.creditAmountMin || financingAmount > scale.creditAmountMax}
        >
          {financingAmount < scale.creditAmountMin || financingAmount > scale.creditAmountMax ? (
            <em>{scale.scaleWording} -&nbsp;Le montant du crédit est incompatible</em>
          ) : (
            scale.scaleWording
          )}
        </MenuItem>
      ))}
    </TextField>
  ) : error ? (
    <Alert
      severity="error"
      action={
        <Button color="inherit" size="small" onClick={() => refetch()}>
          Réessayer
        </Button>
      }
    >
      Une erreur est survenue lors de la récupération des barèmes.
    </Alert>
  ) : (
    <div>
      <CircularProgress />
    </div>
  );
}

export default ArkeaScaleInput;
