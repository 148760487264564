import { Alert } from "@material-ui/lab";
import Decimal from "decimal.js-light";
import { useFormikContext } from "formik";
import React from "react";

export default function TotalRateAlert() {
  const { values } = useFormikContext();

  const totalRate = values.documentDivisions.reduce(
    (acc, documentDivision) => acc.plus(documentDivision.rate || 0),
    new Decimal(0)
  );

  if (!totalRate.equals(new Decimal(100))) {
    return <Alert severity="error">La somme des taux doit être égale à 100</Alert>;
  }
  return null;
}
