import { Typography } from "@material-ui/core";
import React from "react";
import { formatCurrency } from "../../utils/numberFormat";

export default function EstimateProductDiscountReadonly({ detail }) {
  return (
    <Typography variant="body2" style={{ fontStyle: "italic" }}>
      Remise de {formatCurrency(detail.discount < 0 ? detail.discount * -1 : detail.discount)} {detail.discountType}
      {detail.discountIsPercentage && detail.discountInputValue > 0 && ` (${detail.discountInputValue}%)`}
    </Typography>
  );
}
