import React, { Component } from "react";
import { Box, Grid, withStyles, Typography } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { green } from "@material-ui/core/colors";
import { withSnackbar } from "notistack";
import moment from "moment";
import Decimal from "decimal.js-light";

import { getTextFromInsuranceType } from "../../../../services/FinancingService";
import FinancingItem from "./FinancingItem";
import { formatCurrency, formatNumber } from "../../../../utils/numberFormat";
import InputForm from "./Franfinance/InputForm";

const styles = theme => ({
  totalCard: {
    backgroundColor: "#F7F7F7",
    borderRadius: 4,
    padding: `${theme.spacing(2)}px !important`
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

@inject("newEstimateStore", "sessionStore")
@observer
class FranfinanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = { simulationStatus: "DRAFT" };
  }

  componentDidMount() {
    const { newEstimateStore } = this.props;
    const { financingForm } = newEstimateStore;
    if (financingForm && financingForm._franfinance && financingForm._franfinance.simulationResult) {
      this.setState({ simulationStatus: "DONE" });
    }
  }

  handleSimulationFetch = simulation => {
    const { newEstimateStore } = this.props;
    newEstimateStore.patchFinancingForm({
      _franfinance: {
        simulationDate: new Date(),
        simulationResult: { ...simulation }
      },
      financingInstitution: "FRANFINANCE",
      instalmentNumber: simulation.dureeCredit, // simulation.nombreMensualites,
      loanDuration: simulation.nombreMensualites, // simulation.dureeCredit,
      instalmentAmount: simulation.mensualiteSansAssurance,
      totalAmount: simulation.montantTotalDu,
      monthlyCostInsurance: simulation.coutMensuelAssurance,
      insuranceCount: simulation.codeAssurance === "A" ? 0 : 1,
      insuranceType: simulation.codeAssurance,
      instalmentAmountWithInsurance: simulation.mensualiteAvecAssurance,
      totalAmountWithInsurance: new Decimal(
        parseFloat(simulation.montantTotalDu) + parseFloat(simulation.coutTotalAssurance)
      )
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(2),
      overdrawnRate: simulation.tauxDebiteur,
      taeg: simulation.taeg,
      annualPercentageRateOfInsurance: simulation.taea,
      annualMarkUprate: ""
    });
    this.setState({ simulationStatus: "DONE" });
  };

  render() {
    const { amount, totalDeposit, classes, newEstimateStore } = this.props;
    const { simulationStatus } = this.state;

    const { financingForm } = newEstimateStore;

    const dueNumber =
      financingForm._franfinance &&
      financingForm._franfinance.simulationResult &&
      financingForm._franfinance.simulationResult._input
        ? financingForm._franfinance.simulationResult._input.askedDueNumber || ""
        : "";

    const equipmentCode =
      financingForm._franfinance &&
      financingForm._franfinance.simulationResult &&
      financingForm._franfinance.simulationResult._input
        ? financingForm._franfinance.simulationResult._input.equipmentCode || ""
        : "";

    const insuranceType = financingForm.insuranceType
      ? financingForm.insuranceType
      : financingForm.insuranceCount > 0
      ? "D"
      : "A";

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputForm
            financingForm={financingForm}
            initialDueNumber={dueNumber}
            // initialMonthlyAmount={monthlyAmount}
            initialInsuranceType={insuranceType}
            initialEquipmentCode={equipmentCode}
            amount={amount}
            onFetch={this.handleSimulationFetch}
            totalDeposit={totalDeposit}
          />
        </Grid>
        {simulationStatus !== "DRAFT" && (
          <Grid item xs={12} style={{ marginTop: 12 }}>
            <Box style={{ marginTop: 18 }}>
              {simulationStatus === "DONE" && (
                <Box className={classes.totalCard}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem title="Durée du contrat" value={`${financingForm.loanDuration} mois`} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem title="Nombre de mensualités" value={financingForm.instalmentNumber} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem
                        title="Montant des mensualités hors assurance"
                        value={formatCurrency(financingForm.instalmentAmount)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem
                        title="Montant total dû hors assurance"
                        value={formatCurrency(financingForm.totalAmount)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem
                        title="Coût mensuel de l'assurance choisie"
                        value={formatCurrency(financingForm.monthlyCostInsurance)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem
                        title="Type d'assurance"
                        value={getTextFromInsuranceType(financingForm.insuranceType)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem
                        title="Montant des mensualités avec assurance choisie"
                        value={formatCurrency(financingForm.instalmentAmountWithInsurance)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem
                        title="Montant total dû avec assurance choisie"
                        value={formatCurrency(financingForm.totalAmountWithInsurance)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem
                        title="Taux Débiteur Fixe"
                        value={`${formatNumber(financingForm.overdrawnRate, 3)} %`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem
                        title="Taux Annuel Effectif Global (TAEG)"
                        value={`${formatNumber(financingForm.taeg, 3)} %`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem
                        title="Taux Annuel Effectif de l'Assurance (TAEA)"
                        value={
                          financingForm.annualPercentageRateOfInsurance > 0
                            ? `${formatNumber(financingForm.annualPercentageRateOfInsurance, 3)} %`
                            : "-"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinancingItem
                        title="Taux de Majoration Annuel (TMA)"
                        value={
                          financingForm.annualMarkUprate > 0
                            ? `${formatNumber(financingForm.annualMarkUprate, 3)} %`
                            : "-"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                      <Typography variant="caption">
                        Simulation du {moment(financingForm._franfinance.simulationDate).format("DD/MM/yyyy [à] HH:mm")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(FranfinanceForm));
