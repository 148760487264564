import { Button } from "@material-ui/core";
import Decimal from "decimal.js-light";
import { useFormikContext } from "formik";
import React from "react";

export default function SubmitForm() {
  const { values, handleSubmit } = useFormikContext();

  const totalRate = values.documentDivisions.reduce(
    (acc, documentDivision) => acc.plus(documentDivision.rate || 0),
    new Decimal(0)
  );

  return (
    <Button disabled={!totalRate.equals(new Decimal(100))} onClick={handleSubmit} variant="contained" color="primary">
      Enregistrer
    </Button>
  );
}
