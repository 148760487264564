import React from "react";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";

export const SOFINCO_CAMPAIGN_IDS = {
  vac: "Vente à crédit",
  vac_hab: "Vente à crédit habitat",
  vac_prefi: "Vente à crédit préfinancement"
};

export default function SofincoCampaignIdSelect({
  value,
  onChange,
  selectProps = {},
  formControlProps = {},
  hideLabel = false
}) {
  return (
    <FormControl fullWidth variant="outlined" {...formControlProps}>
      {!hideLabel && <InputLabel>Nature de bien</InputLabel>}
      <Select
        id="sofincoCampaignId"
        name="sofincoCampaignId"
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={hideLabel ? undefined : "Identifiant de la campagne"} />}
        displayEmpty
        {...selectProps}
      >
        <MenuItem value="" disabled>
          <em> -- Sélectionnez un identifiant de campagne --</em>
        </MenuItem>
        {Object.entries(SOFINCO_CAMPAIGN_IDS)
          .sort((a, b) => a[1].localeCompare(b[1]))
          .map(([campaignId, campaignIdLabel]) => (
            <MenuItem key={campaignId} value={campaignId}>
              {campaignIdLabel} ({campaignId})
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
