import React, { useState } from "react";
import { TextField, CircularProgress, Button } from "@material-ui/core";
import { useDebounce } from "use-debounce";

import { useInfiniteQuery } from "@tanstack/react-query";
import ClientService from "../../services/ClientService";
import LightRow from "./LightRow";

export default function ClientsListLight({ compact, handleSubmit, clientIdDisabled }) {
  const [focusedClient, setFocusedClient] = useState(null);
  const [q, setQ] = useState("");
  const [debouncedQ] = useDebounce(q, 500);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } = useInfiniteQuery({
    queryKey: ["clients", debouncedQ],
    queryFn: ({ pageParam = 0 }) => ClientService.getClients({ page: pageParam, filter: { q: debouncedQ }, limit: 15 }),
    getNextPageParam: lastPage => lastPage.nextPage,
    refetchOnWindowFocus: false
  });

  const handleQueryChange = e => {
    const {
      target: { value }
    } = e;
    setQ(value);
  };

  const handleClientFocus = clientId => {
    setFocusedClient(clientId === focusedClient ? null : clientId);
  };

  const _clients = data ? data.pages.flatMap(page => page.contacts) || [] : undefined;

  return (
    <div>
      <TextField
        style={{ marginTop: compact ? 5 : 30, marginBottom: compact ? 5 : 30 }}
        onClick={e => e.stopPropagation()}
        name="qClients"
        placeholder="Recherche"
        label="Recherche"
        margin="normal"
        fullWidth
        value={q}
        autoComplete="off"
        variant="outlined"
        onChange={handleQueryChange}
      />
      {_clients ? (
        <div style={{ height: compact ? "50vh" : "45vh", overflowX: "hidden", overflowY: "auto" }}>
          {data.pages.length > 0 ? (
            data.pages.map(group =>
              group.contacts.map(client => (
                <LightRow
                  client={client}
                  key={client.id}
                  showDetails={focusedClient === client.id}
                  handleShowDetails={handleClientFocus}
                  disabled={clientIdDisabled.includes(client.id)}
                  handleSelect={arg => {
                    if (!clientIdDisabled.includes(client.id)) handleSubmit({ ...arg });
                  }}
                />
              ))
            )
          ) : (
            <div>Aucun résultat pour la recherche</div>
          )}
          {(isFetchingNextPage || isFetching) && (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
              <CircularProgress size={40} />
            </div>
          )}
          {hasNextPage && (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 30 }}>
              <Button onClick={() => fetchNextPage()}>Afficher plus</Button>
            </div>
          )}
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
          <CircularProgress size={40} />
        </div>
      )}
    </div>
  );
}
