import { useFormikContext } from "formik";
import React from "react";
import { TextField } from "@material-ui/core";

export default function ArkeaNumberOfPaymentsInput() {
  const { values, setFieldValue, handleBlur, errors, touched } = useFormikContext();

  if (!values.scale || !values.scale.id) {
    return null;
  }

  const handleChangeNumberOfPayments = e => {
    setFieldValue("numberOfPayments", e.target.value);
    setFieldValue("borrowerInsurance.id", "");
    setFieldValue("borrowerInsurance.insuranceWording", "");
  };

  return (
    <TextField
      label="Nombre de mensualités"
      name="numberOfPayments"
      type="number"
      value={values.numberOfPayments}
      onChange={handleChangeNumberOfPayments}
      onBlur={handleBlur}
      required
      error={!!errors.numberOfPayments && touched.numberOfPayments}
      helperText={
        touched.numberOfPayments && errors.numberOfPayments
          ? errors.numberOfPayments
          : touched.numberOfPayments
          ? ""
          : `La valeur doit être comprise entre ${values.scale.numberOfPaymentsMin} et ${values.scale.numberOfPaymentsMax}`
      }
      inputProps={{ min: values.scale.numberOfPaymentsMin, max: values.scale.numberOfPaymentsMax }}
      variant="outlined"
      fullWidth
    />
  );
}
