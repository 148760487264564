import { FormControl, Grid, InputAdornment, Switch, TextField } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Decimal from "decimal.js-light";
import { inject, observer } from "mobx-react";
import React from "react";

function InvoiceProductDiscount({ newInvoiceStore, index }) {
  const row = newInvoiceStore.details[index];
  const discountApplyType = row.discountIsPercentage ? "PERCENTAGE" : "NUMERIC";
  const discountInputValue = row.discountInputValue !== undefined ? row.discountInputValue : row.discount || "";
  const { discountType } = row;

  const onIsPercentageChange = (e, value) => {
    newInvoiceStore.updateDetailDiscount(index, {
      discountIsPercentage: value === "PERCENTAGE",
      discountInputValue,
      discountType
    });
  };

  const onDiscountInputValueChange = e => {
    newInvoiceStore.updateDetailDiscount(index, {
      discountInputValue: e.target.value,
      discountIsPercentage: discountApplyType === "PERCENTAGE",
      discountType
    });
  };

  const onDiscountTypeChange = e => {
    newInvoiceStore.updateDetailDiscount(index, {
      discountType: e.target.checked ? "TTC" : "HT",
      discountInputValue,
      discountIsPercentage: discountApplyType === "PERCENTAGE"
    });
  };

  const isDiscountValid = new Decimal(row.discount || 0).lte(newInvoiceStore.getMaxDiscountForItem(index));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={8} xl={9}>
        <FormControl fullWidth variant="outlined">
          <TextField
            variant="outlined"
            label="Remise"
            id="discount"
            name="discountInputValue"
            type="number"
            error={!isDiscountValid}
            helperText={!isDiscountValid ? "La remise ne peut être supérieure au total" : ""}
            value={discountInputValue}
            onChange={onDiscountInputValueChange}
            inputProps={{ min: "0" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ToggleButtonGroup
                    exclusive
                    value={discountApplyType}
                    onChange={onIsPercentageChange}
                    style={{ height: 56, marginRight: -14 }}
                  >
                    <ToggleButton style={{ width: 56, fontSize: 18 }} value="PERCENTAGE">
                      %
                    </ToggleButton>
                    <ToggleButton style={{ width: 56, fontSize: 18 }} value="NUMERIC">
                      €
                    </ToggleButton>
                  </ToggleButtonGroup>
                </InputAdornment>
              )
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <div style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}>
          <span>Remise HT</span>
          <Switch
            color="primary"
            checked={discountType === "TTC"}
            name="discountType"
            onChange={onDiscountTypeChange}
          />
          <span>Remise TTC</span>
        </div>
      </Grid>
    </Grid>
  );
}

export default inject("newInvoiceStore")(observer(InvoiceProductDiscount));
