import React, { useEffect, useMemo, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { green, orange, red } from "@material-ui/core/colors";
import { useSnackbar } from "notistack";

import UserService from "../services/UserService";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  content: {
    paddingTop: theme.spacing(8)
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      alignItems: "normal",
      flexDirection: "row",
      justifyContent: "space-evenly"
    }
  },
  plan: {
    width: 250,
    minHeight: 200
  },
  available: {
    width: 250,
    minHeight: 200,
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0
    }
  }
}));

function PlanPage({ sessionStore }) {
  const [processing, setProcessing] = useState(true);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    UserService.me()
      .then(res => {
        sessionStore.patchSession(res);
        setProcessing(false);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la récupération des informations", { variant: "error" });
      });
  }, []);

  const availableColor = useMemo(() => {
    const signatures = parseInt(sessionStore.user.company.signatures || 0, 10);
    const plan = parseInt(sessionStore.user.company.plan || 0, 10);
    if (signatures >= 0) return green[500];
    if (Math.abs(signatures) > plan) return red[500];
    return orange[500];
  }, [sessionStore.user]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h4">Information consommation</Typography>
      </Grid>
      <Grid container className={classes.content}>
        {!processing && sessionStore.user && sessionStore.user.company ? (
          <Grid item xs={12}>
            <Box className={classes.wrapper}>
              <Card className={classes.plan}>
                <CardHeader subheader="Mon abonnement" />
                <CardContent>
                  <Typography style={{ textAlign: "center", marginBottom: 24 }}>
                    <Typography variant="h2" component="span">
                      {sessionStore.user.company.plan || 0}
                    </Typography>
                    <Typography variant="body1" component="span">
                      /mois
                    </Typography>
                  </Typography>
                  <Typography variant="caption">
                    Les signatures de votre abonnement sont crédités le 1er de chaque mois
                  </Typography>
                </CardContent>
              </Card>
              <Card className={classes.available}>
                <CardHeader subheader="Signatures disponibles">404</CardHeader>
                <CardContent>
                  <Typography style={{ textAlign: "center", marginBottom: 24 }}>
                    <Typography variant="h2" component="span" style={{ color: availableColor }}>
                      {sessionStore.user.company.signatures}
                    </Typography>
                  </Typography>
                  <Typography variant="caption">
                    Une signature est décomptée lorsqu&apos;un signataire est invité à signer un document
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default inject("sessionStore")(observer(props => <PlanPage {...props} />));
