import { Typography } from "@material-ui/core";
import React from "react";
import ProgressBarWithLabel from "../ProgressBarWithLabel";

export default function DocumentDivisionRow({ documentDivision, currentUser }) {
  return (
    <div>
      <div>
        <Typography variant="body1">
          {documentDivision.user.firstname} {documentDivision.user.lastname}{" "}
          {documentDivision.user.id === currentUser.id && <span style={{ fontWeight: "bold" }}>(Vous)</span>}
        </Typography>
      </div>
      <div>
        <ProgressBarWithLabel variant="determinate" value={documentDivision.rate} />
      </div>
    </div>
  );
}
