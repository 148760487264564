import React, { Component } from "react";
import { withStyles, Grid, Paper, Typography, CircularProgress } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";

import NotFoundPage from "./NotFoundPage";
import BottomConfirmation from "../components/estimate/BottomConfirmation";
import FormStepper from "../components/FormStepper";
import EstimateActionStep from "../components/estimate/EstimateActionStep";
import EstimateClientStep from "../components/estimate/EstimateClientStep";
import EstimateService from "../services/EstimateService";
import { privateRoutes } from "../routes";
import EstimateComplementaryStep from "../components/estimate/EstimateComplementaryStep";

const styles = theme => ({
  container: { padding: theme.spacing() },
  title: { marginBottom: theme.spacing(2) }
});

const BASIC_STEPS = ["Prestations", "Coordonnées"];
const W_PAYMENT_STEPS = ["Prestations", "Coordonnées", "Compléments"];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <EstimateActionStep />;
    case 1:
      return <EstimateClientStep />;
    case 2:
      return <EstimateComplementaryStep />;
    default:
      return <NotFoundPage />;
  }
}

@inject("newEstimateStore", "sessionStore")
@observer
class NewEstimatePage extends Component {
  constructor(props, context) {
    super(props, context);
    const isAnEdition = Boolean(props.match && props.match.params && props.match.params.estimateId);
    this.state = {
      activeStep: 0,
      skipped: new Set(),
      isLoading: isAnEdition
    };
    if (!isAnEdition) {
      if (props.location.state && props.location.state.fromEstimate) {
        props.newEstimateStore.initWithExistant(
          { ...props.location.state.fromEstimate, id: undefined, number: undefined },
          {
            limitDate: props.sessionStore.estimateLimitDate,
            divisions: [
              {
                rate: 100,
                user: { ...props.sessionStore.user, isCurrentUser: true }
              }
            ]
          }
        );
      } else if (props.location.state && props.location.state.client) {
        props.newEstimateStore.initWithClient(props.location.state.client, {
          limitDate: props.sessionStore.estimateLimitDate,
          divisions: [
            {
              rate: 100,
              user: { ...props.sessionStore.user, isCurrentUser: true }
            }
          ]
        });
      } else {
        props.newEstimateStore.init({
          limitDate: props.sessionStore.estimateLimitDate,
          divisions: [
            {
              rate: 100,
              user: { ...props.sessionStore.user, isCurrentUser: true }
            }
          ]
        });
      }
    }
  }

  componentDidMount() {
    const { isLoading } = this.state;
    const {
      history,
      newEstimateStore,
      match: {
        params: { estimateId }
      }
    } = this.props;
    if (isLoading && this.hasAccess()) {
      EstimateService.getEstimate(estimateId)
        .then(estimate => {
          if (estimate.status === "INVOICED") {
            history.push(privateRoutes.admin.estimates.listing.path);
          } else {
            newEstimateStore.initWithExistant(estimate);
            this.setState({ isLoading: false });
          }
        })
        .catch(() => {
          history.push(privateRoutes.admin.estimates.listing.path);
        });
    }
  }

  hasAccess = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["ESTIMATE_WRITE", "ESTIMATE_MY"],
      aclType: "oneof",
      requiredFeatures: ["ESTIMATE"]
    });
  };

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      skipped
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  isStepSkipped(step) {
    const { skipped } = this.state;
    return skipped.has(step);
  }

  isNextStepButtonDisabled(step) {
    const { newEstimateStore } = this.props;
    switch (step) {
      case 0:
        return !newEstimateStore.isNewEstimateActionOK;
      case 1:
        return !newEstimateStore.isNewEstimateClientOK;
      default:
        return false;
    }
  }

  render() {
    const { classes, newEstimateStore } = this.props;
    const { activeStep, isLoading } = this.state;

    if (!this.hasAccess()) return <NotFoundPage />;

    if (isLoading) {
      return (
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} className={classes.loaderWrapper}>
            <CircularProgress size={50} />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h4">
            {newEstimateStore.number ? `Modification du devis ${newEstimateStore.number}` : "Nouveau devis"}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Paper>
            <FormStepper
              activeStep={activeStep}
              steps={newEstimateStore.includePaymentBlock ? W_PAYMENT_STEPS : BASIC_STEPS}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {getStepContent(activeStep)}
        </Grid>
        <Grid item xs={12}>
          <BottomConfirmation
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            activeStep={activeStep}
            steps={newEstimateStore.includePaymentBlock ? W_PAYMENT_STEPS : BASIC_STEPS}
            disableBack={activeStep === 0}
            disableNext={this.isNextStepButtonDisabled(activeStep)}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(NewEstimatePage));
